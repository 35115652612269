import { Box, Button, Divider, FormControl, InputLabel, MenuItem, Modal, Pagination, Select, Stack, TextField, Typography, useAutocomplete } from '@mui/material'
import React, { useState } from 'react'
import { styled } from '@mui/system';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  p: 4,
};

const options = ['sku1', 'sku2', 'sku3', 'sku4', 'sku5'];


export default function UnmappedTable({ UnmapProductData, pageNumber, handlePageNumber }) {
  const [openLinkModal, setOpenLinkModal] = useState(false)
  const [mapChannelInfo, setMapChannelInfo] = useState(null);
  const [selectedSku, setSelectedSku] = useState(null);


  //const [value, setValue] = React.useState(options[0]);
  const [inputValue, setInputValue] = React.useState('');

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    focused,
  } = useAutocomplete({
    id: 'controlled-state-demo',
    options,
    selectedSku,
    onChange: (event, newValue) => setSelectedSku(newValue),
    inputValue,
    onInputChange: (event, newInputValue) => setInputValue(newInputValue),
  });

  const handleOpenMapSkuModal = (channel_info) => {
    setMapChannelInfo(channel_info);
    setOpenLinkModal(true);
  }
  const handleCloseMapSkuModal = () => {
    setMapChannelInfo(null);
    setOpenLinkModal(false);
  }

  return (
    <div>
    
     
      
      <div className="table-responsive">
        <table className="table table-hover mt-2">
          <thead style={{ borderBottom: "1px solid black" }}>
            <tr>
              <th scope="col">CHANNEL ID</th>
              <th scope="col">CHANNEL SKU</th>
              <th scope="col">CHANNEL PRODUCT ID</th>
              <th scope="col">CHANNEL PRODUCT TITLE</th>
              <th scope="col">CHANNEL PRICE</th>
              <th scope="col">CHANNEL SALE PRICE</th>
              <th scolpe="col">LINK</th>
              <th scope="col">SELECT ALL</th>
            </tr>
          </thead>
          <tbody>
            {UnmapProductData?.content?.map((data) => {
              return (
                <tr key={"unmap" + data.Id}>
                  <td>{data?.channelId}</td>
                  <td>{data.channelSku}</td>
                  <td>{data.channelProductId}</td>
                  <td>{data.channelProductTitle}</td>
                  <td>{data.channelPrice}</td>
                  <td>{data.channelSalePrice}</td>
                  <td><button onClick={() => { handleOpenMapSkuModal({ channel_sku: data.channelSku, channel_product_title: data.channelProductTitle }) }} className='btn btn-sm btn-outline-warning'>MAP SKU</button></td>
                  <td><button className='btn btn-sm btn-outline-danger'>Delete</button></td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className="right">
          <Stack spacing={2}>
            <Pagination color="primary" count={UnmapProductData?.totalPages} page={pageNumber} onChange={(e, v) => handlePageNumber(v)}/>
          </Stack>
        </div>
      </div>

      <Modal
        open={openLinkModal}
        onClose={handleCloseMapSkuModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <i className="fa-solid fa-link fa-2x px-2" style={{ color: "#205dc5" }}></i>
            LINK PRODUCT {selectedSku}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <b>CHANNEL SKU : </b> {mapChannelInfo?.channel_sku}
          </Typography>
          <Typography id="modal-modal-description" sx={{ my: 2 }}>
            <b>CHANNEL PRODUCT TITLE : </b> {mapChannelInfo?.channel_product_title}
          </Typography>
          <Layout>
            <small><b>Select sku *</b></small>
            <StyledAutocomplete>
              <StyledInputRoot {...getRootProps()} className={focused ? 'focused' : ''}>
                <StyledInput {...getInputProps()} />
              </StyledInputRoot>
              {groupedOptions.length > 0 && (
                <StyledListbox {...getListboxProps()}>
                  {groupedOptions.map((option, index) => (
                    <StyledOption {...getOptionProps({ option, index })}>
                      {option}
                    </StyledOption>
                  ))}
                </StyledListbox>
              )}
            </StyledAutocomplete>
          </Layout>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 3, py: 2 }}
          >
            Link
          </Button>
          <Divider>OR</Divider>
          <TextField
            autoComplete="given-name"
            name="pincode"
            fullWidth
            id="newSku"
            className="mt-3"
            label="SKU"
          />
          <TextField
            autoComplete="given-name"
            name="skuQuantity"
            fullWidth
            className="my-2"
            id="skuQuantity"
            label="Quantity"
          />
          <FormControl sx={{ minWidth: "100%" }}>
            <InputLabel id="demo-map-select-warehouse-label">Select Warehouse </InputLabel>
            <Select
              labelId="demo-map-select-warehouse-label"
              id="select-map-warehouse"
              value={selectedSku}
              label="Select Warehouse"
              onChange={(e) => { setSelectedSku(e.target.value) }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={'warehouse1'}>warehouse1</MenuItem>
              <MenuItem value={'warehouse2'}>warehouse2</MenuItem>
            </Select>
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 3, py: 2 }}
          >
            Created
          </Button>
        </Box>
      </Modal>
    </div>
  )
}


const blue = {
  100: '#DAECFF',
  200: '#99CCF3',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#f6f8fa',
  100: '#eaeef2',
  200: '#d0d7de',
  300: '#afb8c1',
  400: '#8c959f',
  500: '#6e7781',
  600: '#57606a',
  700: '#424a53',
  800: '#32383f',
  900: '#24292f',
};

const StyledAutocomplete = styled('div')`
  position: relative;
  margin: 3px 0 10px 0;
`;

const StyledInputRoot = styled('div')(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[500]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  display: flex;
  gap: 5px;
  padding: 7px 7px 7px 0px;
  overflow: hidden;
  width: 100%;

  &.focused {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[500] : blue[200]};
  }

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus-visible {
    outline: 0;
  }
`,
);

const StyledInput = styled('input')(
  ({ theme }) => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: inherit;
  border: none;
  border-radius: inherit;
  padding: 8px 12px;
  outline: 0;
  flex: 1 0 auto;
`,
);

const StyledListbox = styled('ul')(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  width: 100%;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  max-height: 300px;
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  box-shadow: 0px 4px 30px ${theme.palette.mode === 'dark' ? grey[900] : grey[200]};
  `,
);

const StyledOption = styled('li')(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    cursor: pointer;
  }

  &[aria-selected=true] {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }

  &.Mui-focused,
  &.Mui-focusVisible {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }

  &.Mui-focusVisible {
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[500] : blue[200]};
  }

  &[aria-selected=true].Mui-focused,
  &[aria-selected=true].Mui-focusVisible {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }
  `,
);

const Layout = styled('div')`
  display: flex;
  flex-flow: column nowrap;
`;

const Pre = styled('pre')(({ theme }) => ({
  margin: '0.5rem 0',
  '& code': {
    backgroundColor: theme.palette.mode === 'light' ? '#ebebef' : '#25252d',
    color: theme.palette.mode === 'light' ? '#000' : '#fff',
    padding: '0.125rem 0.25rem',
    borderRadius: 3,
  },
}));