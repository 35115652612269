import React from 'react'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';import { Tooltip } from '@mui/material';

export default function HelpTooltip({msg}) {
    return (
        <>
            {
                (msg !== 'na' && msg !== 'NA') &&
                <Tooltip className='pointer' title={msg} >
                    <HelpOutlineIcon />
                </Tooltip>
            }
        </>
    )
}
