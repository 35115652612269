import axios from "axios"
import { BASE_URL, apiErrorManagemnt } from "./Custom"
import { getToken } from "../custome/action"

export const getStatesList = async (coutryId) => {
    return await axios.get(`${BASE_URL}/manageecom/api/getStates?countryId=${coutryId}`, {
        headers: {
            'Authorization': 'Bearer ' + getToken(),
            'Content-Type': 'application/json'
        }
    }).then((res)=>{
        return res;
    },(err)=>{
        apiErrorManagemnt(err);
        return err;
    }).catch(e=>{
        return e;
    })
}

export const getCitysList = async (stateId) => {
    return await axios.get(`${BASE_URL}/manageecom/api/getCitys?stateId=${stateId}`, {
        headers: {
            'Authorization': 'Bearer ' + getToken(),
            'Content-Type': 'application/json'
        }
    }).then((res)=>{
        return res;
    },(err)=>{
        apiErrorManagemnt(err);
        return err;
    }).catch(e=>{
        return e;
    })
}


// //this is save single stock details
// export const saveWarehouseRecord = async (data) => {
//     const rs = await axios.post(`${BASE_URL}/manageecom/api/saveWarehouseRecord`, data, {
//         headers: {
//             'Authorization': 'Bearer ' + getToken(),
//             'Content-Type': 'application/json'
//         }
//     }).then((res)=>{
//         return res;
//     },(err)=>{
//         apiErrorManagemnt(err);
//         return err;
//     }).catch(e=>{
//         return e;
//     })
//     return rs;
// }

// export const updateWarehouseRecord = async (data) =>{
//         const rs= await axios.post(`${BASE_URL}/manageecom/api/updateWarehouseRecord`, data, {
//             headers: {
//                 'Authorization': 'Bearer ' + getToken(),
//                 'Content-Type': 'application/json'
//             }
//         }).then((res)=>{
//             return res;
//         },(err)=>{
//             apiErrorManagemnt(err);
//             return err;
//         }).catch(e=>{
//             return e;
//         })
//         return rs;
// }