import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { SELLERS_BASE_URL } from '../../api/Custom'
import { getToken } from '../../custome/action'

export const ordersApi = createApi({
    reducerPath: 'ordersApi',
    baseQuery: fetchBaseQuery({ baseUrl: SELLERS_BASE_URL }),
    tagTypes : ['orders'],
    endpoints: (builder) => ({
        getMarketplaceOrderDetails: builder.query({
            query: (status) => ({
                url: `/getMarketplaceOrders?status=${status}`,
                method: 'GET',
                headers:  {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            providesTags : ['orders']
        }),

        getMarketplaceOrdersByWarehouse: builder.query({
            query: (data) => ({
                url: `/getMarketplaceOrdersByWarehouse?status=${data?.status}&warehouseId=${data?.warehouseId}`,
                method: 'GET',
                headers:  {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            providesTags : ['orders']
        }),
        
        updateMarketplaceOrderStatus : builder.mutation({
            query : (data) =>({
                url: `/updateMarketplaceOrderStatus`,
                method: 'POST',
                body:data,
                headers:  {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            invalidatesTags:['orders']
        }),

        shipMarketplaceOrder : builder.mutation({
            query : (data) =>({
                url: `/shipMarketplaceOrders`,
                method: 'POST',
                body:data,
                headers:  {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            invalidatesTags:['orders']
        })
       
    })
})

export const { useGetMarketplaceOrderDetailsQuery, useUpdateMarketplaceOrderStatusMutation ,useGetMarketplaceOrdersByWarehouseQuery ,useShipMarketplaceOrderMutation} = ordersApi