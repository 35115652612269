import { useMutation, useQuery, useQueryClient } from "react-query";
import { getComboDataDetails, saveComboData, updateJSONComboData } from "../api/ComboData";

export const useComboData = (pageNumber) => {
    return useQuery(
        ['combo-data',pageNumber],
        ()=>getComboDataDetails(pageNumber),
        {
            staleTime:60000
        }
    );
} 

export const useSaveComboData = () =>{
    const queryClient = useQueryClient();
    return useMutation(saveComboData,{
        onSuccess : ()=>{
            queryClient.invalidateQueries(['combo-data',1])
        }
    })
}

export const useUpdateJsonComboData = (pageNumber) =>{
    const queryClient = useQueryClient();
    return useMutation(updateJSONComboData,{
        onSuccess : ()=>{
            queryClient.invalidateQueries(['combo-data',pageNumber]);
        }
    })
}