import React, { useState } from 'react'
import { Button, TextField, Typography } from '@mui/material'
import './rechargePlans.css'
import { BASE_URL, SELLERS_BASE_URL } from '../../api/Custom';
import axios from 'axios'
import { getToken } from '../../custome/action';
import logo from '../../files/imgs/manageEcom-logo.png'
import Swal from 'sweetalert2';
import { savePaymentDetails } from './payment';
import { useSelector } from 'react-redux';
import { DASHBOARD_BASE_PATH } from '../../custome/constants';
import { useNavigate } from 'react-router-dom';


export default function RechargePlans() {

    const [customUnits, setCustomeUnits] = useState(0);
    const [invalidNumError, setInvalidNumError] = useState(false);
    const [loader,setLoader]= useState({type:null,loading:false});
    const navigate = useNavigate();

    // Getting user data from the Redux store
    const userData = useSelector(state => state?.user?.user);

    if (userData === null || userData === undefined) {
        window.location.assign(DASHBOARD_BASE_PATH);
    }
    
    function checkString(event) {
        if (/^[0-9]*$/.test(event.target.value)) {
            setCustomeUnits(event.target.value)
            setInvalidNumError(false)
        } else {
            setInvalidNumError(true)
        }
    }

    



    const recharge =  (cardName) => {
        
        if (userData?.user?.gstIn === null || userData.user?.gstIn === "") {
            Swal.fire({
              title: "GST number not present. Please provide first.'",
              showDenyButton: true,
              showCancelButton: true,
              confirmButtonText: "Provide Here",
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                navigate("/dashboard/update_profile");
              }
            });
            return;
          }

        var data;
      
        if (loader?.loading===false && cardName === 'CUSTOM_CARD') {
            if (!invalidNumError) {
                if(customUnits==0 ){
                    Swal.fire("Invalid Request","amount or unit must be greter than 0","warning");
                    return;
                }
                data = {
                    amt: customUnits,
                    units: customUnits,
                    billing_plan: 'C',
                }
                setLoader({type:"C",loading:true});
            }else{
                return;
            }
        } else if (loader?.loading===false && cardName === 'PREPAID_CARD') {
            data = {
                amt: 1000,
                units: 1000,
                billing_plan: 'E',
            }
            setLoader({type:"E",loading:true});
        } else if (loader?.loading===false && cardName === '11000_CARD') {
            data = {
                amt: 10999,
                units: 11000,
                billing_plan: 'G',
            }
            setLoader({type:"G",loading:true});
        } else {
            return;
        }
        savePaymentDetails(data).then(res=>{
            setLoader({type:null,loading:false});
        })
        
    }

    return (
        <>
            <div className="container-fluid">
                <div className="text-center text-muted mt-2">
                    <h2>BILLING PLANS</h2>
                    <h4>7 DAYS MONEY BACK GUARANTEE</h4>

                </div>
                <div className="row custome-center my-5 px-lg-2">
                    <div className="col-12 col-sm-4 ">
                        <div className="card success-card">
                            <div className="card-header p-3 text-muted text-center ">
                                <Typography variant="h5" component="h2">
                                    <b>PREPAID UNITS</b>
                                </Typography>
                            </div>
                            <div className="card-body">
                                <Typography mt={1} className="text-muted" id="modal-modal-title" variant="h6" component="h2">
                                    <b>Units : <span className='text-dark'>1000</span></b>
                                </Typography>
                                <Typography mt={1} className="text-muted" id="modal-modal-title" variant="h6" component="h2">
                                    <b>Amount : <span className='text-dark'>1000 ₹</span></b>
                                </Typography>
                                <Typography mt={1} className="text-muted" id="modal-modal-title" variant="h6" component="h2">
                                    <b>Order processing: <span className='text-dark'>1 ₹/unit</span></b>
                                </Typography>
                                <Typography mt={1} className="text-muted" id="modal-modal-title" variant="h6" component="h2">
                                    <b>Listing: <span className='text-dark'>1 ₹/listing</span></b>
                                </Typography>
                                <Typography mt={1} className="text-muted" id="modal-modal-title" variant="h6" component="h2">
                                    <b>Payment reconciliation: <span className='text-dark'>YES</span></b>
                                </Typography>
                                <Typography mt={1} className="text-muted" id="modal-modal-title" variant="h6" component="h2">
                                    <b>GST filing: <span className='text-dark'>200/filing</span></b>
                                </Typography>
                            </div>
                            <div className="cars-footer p-1">
                                <Button
                                    fullWidth
                                    type='submit'
                                    disabled={(loader?.loading && loader?.type==="E")?true:false}
                                    onClick={() => { recharge("PREPAID_CARD") }}
                                    variant="contained"
                                    sx={{ mt: 2, py: 1, }}
                                >
                                     {
                                        
                                        (loader?.type==="E" && loader?.loading===true)?
                                        
                                        <>Loading...</>
                                        :
                                        <>ACCEPT & PAY</>
                                    }
                                </Button>
                            </div>
                        </div>
                    </div>


                    <div className="col-12 col-sm-4 ">
                        <div className="card success-card">
                            <div className="card-header p-3 text-muted text-center">
                                <Typography variant="h5" component="h2">
                                    <b>CUSTOM UNITS</b>
                                </Typography>
                            </div>
                            <div className="card-body">
                                <TextField

                                    autoComplete="given-name"
                                    name="customUnits"
                                    fullWidth
                                    id="customUnits"
                                    onChange={(event) => checkString(event)}
                                    label="Enter units quntity"
                                />
                                {invalidNumError && <span className='text-danger'>please enter valid number!!</span>}
                                <Typography mt={1} className="text-muted" id="modal-modal-title" variant="h6" component="h2">
                                    <b>Units : <span className='text-dark'>{customUnits}</span></b>
                                </Typography>
                                <Typography mt={1} className="text-muted" id="modal-modal-title" variant="h6" component="h2">
                                    <b>Amount : <span className='text-dark'>₹ {customUnits} </span></b>
                                </Typography>
                                <Typography mt={1} className="text-muted" id="modal-modal-title" variant="h6" component="h2">
                                    <b>Order processing: <span className='text-dark'>1 ₹/unit</span></b>
                                </Typography>
                                <Typography mt={1} className="text-muted" id="modal-modal-title" variant="h6" component="h2">
                                    <b>Listing: <span className='text-dark'>1 ₹/listing</span></b>
                                </Typography>
                                <Typography mt={1} className="text-muted" id="modal-modal-title" variant="h6" component="h2">
                                    <b>Payment reconciliation: <span className='text-dark'>YES</span></b>
                                </Typography>
                                <Typography mt={1} className="text-muted" id="modal-modal-title" variant="h6" component="h2">
                                    <b>GST filing: <span className='text-dark'>200/filing</span></b>
                                </Typography>
                            </div>
                            <div className="cars-footer p-1">
                                <Button
                                    onClick={() => { recharge("CUSTOM_CARD") }}
                                    fullWidth
                                    disabled={(loader?.loading && loader?.type==="C")?true:false}
                                    type='submit'
                                    variant="contained"
                                    sx={{ mt: 2, py: 1, }}
                                >
                                    {
                                        (loader?.type==="C" && loader?.loading===true)?
                                        
                                        <>Loading...</>
                                        :
                                        <>ACCEPT & PAY</>
                                    }
                                    
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-4 ">
                        <div className="card success-card">
                            <div className="card-header p-3 text-muted text-center">
                                <Typography variant="h5" component="h2">
                                    <b>11000 UNITS</b>
                                </Typography>
                            </div>
                            <div className="card-body">
                                <Typography mt={1} className="text-muted" id="modal-modal-title" variant="h6" component="h2">
                                    <b>Units : <span className='text-dark'>11000</span></b>
                                </Typography>
                                <Typography mt={1} className="text-muted" id="modal-modal-title" variant="h6" component="h2">
                                    <b>Amount : <span className='text-dark'>10999 ₹</span></b>
                                </Typography>
                                <Typography mt={1} className="text-muted" id="modal-modal-title" variant="h6" component="h2">
                                    <b>Order processing: <span className='text-dark'>1 ₹/unit</span></b>
                                </Typography>
                                <Typography mt={1} className="text-muted" id="modal-modal-title" variant="h6" component="h2">
                                    <b>Listing: <span className='text-dark'>1 ₹/listing</span></b>
                                </Typography>
                                <Typography mt={1} className="text-muted" id="modal-modal-title" variant="h6" component="h2">
                                    <b>Payment reconciliation: <span className='text-dark'>YES</span></b>
                                </Typography>
                                <Typography mt={1} className="text-muted" id="modal-modal-title" variant="h6" component="h2">
                                    <b>GST filing: <span className='text-dark'>200/filing</span></b>
                                </Typography>
                            </div>
                            <div className="cars-footer p-1">
                                <Button
                                    fullWidth
                                    onClick={() => { recharge('11000_CARD') }}
                                    type='submit'
                                    disabled={(loader?.loading && loader?.type==="G")?true:false}
                                    variant="contained"
                                    sx={{ mt: 2, py: 1, }}
                                >
                                    {
                                        (loader?.type==="G" && loader?.loading===true)?
                                        
                                        <>Loading...</>
                                        :
                                        <>ACCEPT & PAY</>
                                    }
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
