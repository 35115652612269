import React from 'react'
import './sheduler.css'
import growthImg from '../../files/imgs/growth.svg'
export default function Sheduler() {
    return (
        <div className=''>
            <div className='row sheduler-wrapper'>
                <div className='col-sm-12 col-md-6'>
                    <div className='s-right'>
                        <h1>E-Commerce Selling<br />
                            <span className="color-s1">simplified</span><span className="color-s2"> across</span><br />
                            the globe</h1>
                        <div className='separator-div'></div>
                        <p className='para text-muted'>Automate the supply chain of your business with a unified view of operations from a single source of data by using Unicommerce’s cost-efficient e-commerce solutions.</p>
                        <button className='shedule-btn'>SHEDULE A CALL WITH SALES</button>
                    </div>

                </div>
                <div className='col-md-6 right'>
                    <img className='d-none d-md-block' width="480" height="500" style={{ widht: "480px", height: "500px" }} src={growthImg} alt="manageecom growth" />
                </div>
            </div>
        </div>
    )
}
