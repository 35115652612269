import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { SELLERS_BASE_URL } from '../../api/Custom'
import { getToken } from '../../custome/action'

export const mapUnmapProductDataApi = createApi({
    reducerPath: 'mapUnmapProductDataApi',
    baseQuery: fetchBaseQuery({ baseUrl: SELLERS_BASE_URL }),
    tagTypes : ['mapUnmapProductData'],
    endpoints: (builder) => ({
        getChannelProductData: builder.mutation({
            query: (status) => ({
                url: `/getChannelProducts?pageNumber=${status?.pageNumber - 1}&type=${status?.type}&searchBy=${status?.searchBy}&searchValue=${status?.searchValue}`,
                method: 'GET',
                headers:  {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            providesTags : ['mapUnmapProductData']
        }),
    })
})

export const { useGetChannelProductDataMutation } = mapUnmapProductDataApi