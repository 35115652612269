import React, { useState } from 'react'
import { Box, Button, Card, CardContent, Chip, Divider, IconButton, LinearProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SnapdealSetting from './SnapdealSetting';
import AmazonSetting from './AmazonSetting';
import FlipcartSetting from './FlipkartSetting';
import Loader from '../../../components/loader/Loader';
import { removeUpdateDetails, setUpdateDetails } from '../../../store/updateData';
import { useDispatch } from 'react-redux';
import { UPDATE_TYPE_CHANNEL } from '../../../custome/constants';
import { apiErrorManagemnt } from '../../../api/Custom';
import { useGetAllChannelsQuery } from '../../../hooks/RTK_Service/channel';
import { syncOrder } from '../../../api/manageChannel';
import { Toast } from '../../../custome/popupMessage';
import amazonLogo from '../../../files/imgs/amzon_logo2.png'
import flipkartLogo from '../../../files/imgs/flipkart_logo2.png'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import FlipToBackIcon from '@mui/icons-material/FlipToBack';
import Information from '../../../components/custom/Information';

export default function ManageChannel() {

    const [settingData, setSettingData] = useState(0);
    const [snapdeal, setSnapdeal] = React.useState(false);
    const [amazon, setAmazon] = React.useState(false);
    const [flipcart, setFlipcart] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loader, setLoader] = useState({ isActive: false, type: null })

    const handleSnapdeal = (data) => {
        setSettingData(data);
        setSnapdeal(!snapdeal);
    }

    const handleAmazon = (data) => {
        setSettingData(data);
        setAmazon(!amazon);
    }

    const handleFlipcart = (data) => {
        setSettingData(data);
        setFlipcart(!flipcart);
    }

    const { data, isError, isLoading, isFetching, error } = useGetAllChannelsQuery({page:1,limit:10});

    if (isLoading || isFetching) {
        return <Loader action={true} />
    }

    if (isError) {
        apiErrorManagemnt(error)
    }

    const handleUpdateChannelDetails = (data) => {
        dispatch(setUpdateDetails({ type: UPDATE_TYPE_CHANNEL, data: data }));
        navigate("/dashboard/add_channel");
    }

    const handleAddChannelDetails = () => {
        dispatch(removeUpdateDetails())
        navigate("/dashboard/add_channel");
    }

    const handleSyncOrder = (channelId, action) => {
        try {
            setLoader({ isActive: true, type: 'order_sync' })

            syncOrder(channelId, action).then(res => {
                if (res?.data?.status == 0) {
                    Toast.fire({
                        icon: 'info',
                        title: res?.data?.message
                    })
                } else {
                    Toast.fire({
                        icon: 'error',
                        title: res?.data?.message
                    })
                }
                setLoader({ isActive: false, type: null })
            },
                error => {
                    Toast.fire({ icon: 'error', title: error })
                    setLoader({ isActive: false, type: null })
                }
            )
        } catch (error) {
            console.log(error)
            setLoader({ isActive: false, type: null })
        }

    }

    return (
        <>{
            data &&
            <>

                <div className='text-muted'>
                    <div className='alignCenter'>
                        <h2 className='mx-3'>MANAGE CHANNEL</h2>
                        <Information info="add and manage channels details.." />
                    </div>

                    <div className='left mx-3 my-2'>
                        <Button onClick={() => { handleAddChannelDetails() }} variant="contained">+ ADD CHANNEL</Button>
                    </div>
                    <div className="container-fluid mt-3">
                        {
                            loader.isActive &&
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box>
                        }


                        <div className='row my-2'>
                            {data?.map((item) => (
                                <div className='col-12 col-md-4'>
                                    <Card key={item.channelId} style={{ minHeight: "280px" }} className="my-1 mx-0" >
                                        <CardContent className='pb-0'>
                                            <div className='spaceBetween'>
                                                <Chip label={item.channelId + " : " + item?.channelName} />
                                                {
                                                    item?.channel === 'FLIPKART' &&
                                                    <img src={flipkartLogo} />
                                                }
                                                {
                                                    item?.channel === 'AMAZON' &&
                                                    <img src={amazonLogo} />
                                                }
                                            </div>


                                            <div style={{ minHeight: "63px" }} >
                                                <h6 className='mb-0 pb-0'>{item.channelSellerName}</h6>
                                                <small className='m-0 p-0 '>{item?.addressWithoutPinCode}</small>
                                                <small><b>, {item?.pincode}</b></small>
                                            </div>
                                            <div className='center mt-2 mb-3'>
                                                {/* <Chip size="small" color="warning" label={"GST " + item?.channelGst} /> */}
                                                {item.orderSync === 'Y' ? <Chip className="mx-1" size="small" color="success" label="order sync enable" /> : <Chip color="error" className="mx-1" size="small" label="order sync disable" />}
                                                {item.inventorySync === 'Y' ? <Chip size="small" color="success" label="inventory sync enable" /> : <Chip color="error" size="small" label="inventory sync disable" />}
                                            </div>
                                            <Divider>
                                                <Chip label="syncing" size="small" />
                                            </Divider>
                                            <div className='center my-3'>
                                                <>
                                                <Button  size='small' variant="outlined" startIcon={<PlaylistAddIcon />}>
                                                    Listing
                                                </Button>

                                                <Button className='mx-3 ' size='small' variant="outlined" startIcon={<FlipToBackIcon />} onClick={() => { handleSyncOrder(item.channelId, "order_sync") }}>
                                                    Order
                                                </Button>
                                                <Button size='small' variant="outlined" startIcon={<PlaylistAddIcon />} onClick={() => { handleSyncOrder(item.channelId, "inventory_sysc") }}>
                                                    Inventory
                                                </Button>
                                                </>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                <>
                                                    <IconButton onClick={() => { handleUpdateChannelDetails(item) }} color="warning" aria-label="add an alarm">
                                                        <EditIcon />
                                                    </IconButton>
                                                    {item.channel === 'AMAZON' && <IconButton onClick={() => { handleAmazon({ userId: item.userId, channelId: item.channelId, channel: item.channel }) }} ><SettingsIcon /></IconButton>}
                                                    {item.channel === 'FLIPKART' && <IconButton onClick={() => { handleFlipcart({ userId: item.userId, channelId: item.channelId, channel: item.channel }) }} ><SettingsIcon /></IconButton>}
                                                    {item.channel === 'SNAPDEAL' && <IconButton onClick={() => { handleSnapdeal({ userId: item.userId, channelId: item.channelId, channel: item.channel }) }} ><SettingsIcon /></IconButton>}
                                                </>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>


                <SnapdealSetting infoData={settingData} snapdeal={snapdeal} handleSnapdeal={() => { handleSnapdeal() }} />
                <AmazonSetting infoData={settingData} amazon={amazon} handleAmazon={() => { handleAmazon() }} />
                <FlipcartSetting infoData={settingData} flipcart={flipcart} handleFlipcart={() => { handleFlipcart() }} />
            </>
        }
        </>
    )
}
