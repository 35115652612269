import axios from "axios";
import { BASE_URL } from "./Custom";
import { getToken } from "../custome/action";


export const syncOrder = async (channelId,action)=>{
    return axios.get(`${BASE_URL}/manageecom/api/syncOrder?channelId=${channelId}&action=${action}`, {
        headers: {
            'Authorization': 'Bearer ' + getToken(),
            'Content-Type': 'application/json'
        }
    })
}