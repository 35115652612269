import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';

import { useDispatch } from 'react-redux';
import {  useNavigate } from 'react-router-dom';

const steps = ['Select category and sku', 'Fill product details', 'Add bullet points', "Select Images"];

export default function PlaceOrder() {
    const [activeStep, setActiveStep] = useState(0);
    const [stepOneData, setStepOneData] = useState(null);
    const [stepTwoData, setStepTwoData] = useState(null);
    const [stepThreeData, setStepThreeData] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isStepOptional = (step) => {
        return step === 9;
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setStepOneData(null);
        setStepTwoData(null);
        setStepThreeData(null);
        setActiveStep(0);
    };

    const handleSaveProductDetails = () => {
        const data = new FormData();

    }

    return (
        <Box className="center my-5">
            <Box sx={{ width: '90%' }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepOptional(index)) {
                            labelProps.optional = (
                                <Typography variant="caption">Optional</Typography>
                            );
                        }

                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {activeStep === 3 ? (
                    <React.Fragment>
                        <Box className="center my-5">
                            <Grid className='center' container xs={12} sm={6} md={6} >
                                <Box className="card p-3 m-3">
                                    <Typography sx={{ mt: 2, mb: 1 }}>
                                        All steps completed - you&apos;re able place order !!
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        <Button className='mx-1' variant="outlined" onClick={handleReset}>Reset</Button>
                                        <Button onClick={handleSaveProductDetails} variant='contained' >Save product details</Button>
                                    </Box>
                                </Box>
                            </Grid>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {
                            activeStep === 0 && <>step one</>
                        }

                        {
                            activeStep === 1 && <>step two</>
                        }

                        {
                            activeStep === 2 && <>step three</>
                        }
                        
                    </React.Fragment>
                )}
            </Box>

        </Box>
    );
}