import React, { useEffect, useState } from 'react'
import { Button, Pagination, Stack, Tooltip } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from '../../../store/loader';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CachedIcon from '@mui/icons-material/Cached';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
//import { getStockDetails, getStockDetailsByWarehouseId } from '../../../api/manageStock';
import './manageStock.css'
import { removeWarehouseData, saveWarehouseData } from '../../../store/warehouseSlice';
import { Toast } from '../../../custome/popupMessage';
import { removeUpdateDetails, setUpdateDetails } from '../../../store/updateData';
import { DASHBOARD_BASE_PATH, UPDATE_TYPE_STOCK } from '../../../custome/constants';

import { useDeleteStockDetailMutation, useGetStockDetailsByWarehouseIdMutation, useGetStockDetailsMutation, useSearchStocksMutation } from '../../../hooks/RTK_Service/stockApi';
import SearchStock from './SearchStock';
import GuideTour from '../../../components/guide/GuideTour';
import { steps } from './guideSteps';
import TakeTourBtn from '../../../components/custom/TakeTourBtn';
import Information from '../../../components/custom/Information';
import { dawnloadExcelFile } from '../../../api/JobManagement';
import BtnLodderIcon from '../../../components/custom/BtnLodderIcon';
import Swal from 'sweetalert2';
import LinearProressBar from '../../../components/custom/LinearProressBar';

export default function ManageStock() {
  const queryParameters = new URLSearchParams(window.location.search)
  const track_path = queryParameters.get("tpath")

  const [startTour, setStartTour] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [stockData, setStockData] = useState([]);
  const [warehouseData, setWarehouseData] = useState([]);
  const [isZeroWarehouse, setIsZeroWarehouse] = useState(false);
  const [isActiveSearch, setActiveSearch] = useState(false);
  const [refresh, setRefresh] = useState(false);


  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [searchStockMutation, { isError: searchIsError, error: searchError, data: searchData, isSuccess: searchSuccess, isLoading:searchStockLoading }] = useSearchStocksMutation();
  const [getStockDetailsMutation, { isError: sIsError, error: sError, isLoading:getStockLoading }] = useGetStockDetailsMutation();
  const [deleteStockdetailsMutation, deleteStcokAction] = useDeleteStockDetailMutation();
  const [getStockDetailsByWarehouseIdMutation, { isError: swIsError, error: swError, isLoading: getStockByWarehouseLoading }] = useGetStockDetailsByWarehouseIdMutation();

  const isWarehouseSelected = useSelector((state) => state.warehouse.warehouse_is_selected);
  const warehouse = useSelector((state) => state.warehouse.warehouse)

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
  }


  const getStockData = async (data) => {
    dispatch(showLoading());
    if (isWarehouseSelected || data != null) {
      const warehouseId = data != null ? data.warehouseId : warehouse.warehouseId;
      getStockDetailsByWarehouseIdMutation({ warehouseId, pageNumber }).then((responce) => {
        const res = responce?.data;
        dispatch(hideLoading());
        if (res.status === 0) {
          setStockData(res.stocks);
        }
      }, (err) => {
        dispatch(hideLoading());
      })
    } else {
      getStockDetailsMutation(pageNumber).then((responce) => {
        const res = responce?.data;
        if (res.status === 0) {

          if (res.type === 'stock') {
            setStockData(res.stocks);
            dispatch(saveWarehouseData(res.warehouse));

            if (track_path != null || track_path?.length > 1) {
              navigate(DASHBOARD_BASE_PATH + track_path);
            }
          } else if (res.type === 'warehouse') {
            setWarehouseData(res.warehouses)
          }
        } else if (res.status === 1) {
          setIsZeroWarehouse(true);
          Toast.fire({
            icon: 'warning',
            title: res?.message
          })
        }
        dispatch(hideLoading());
      }, (err) => {
        dispatch(hideLoading());
      })
    }
  }

  const selectWarehouse = (data) => {
    dispatch(saveWarehouseData(data));
    if (track_path != null || track_path?.length > 1) {
      navigate(DASHBOARD_BASE_PATH + track_path);
    } else {
      getStockData(data);
    }
  }
  const addStockHandler = () => {
    dispatch(removeUpdateDetails());
    navigate("/dashboard/add_or_update_stock");
  }
  const updateHandler = (data) => {
    dispatch(setUpdateDetails({ type: UPDATE_TYPE_STOCK, data: data }))
    navigate("/dashboard/add_or_update_stock");
  }

  // const dawnloadExcelProductFile = () => {
  //   fetch(SELLERS_BASE_URL + "/downloadStockDataExlFile?warehouseId=" + warehouse?.warehouseId, {
  //     method: 'GET',
  //     headers: {
  //       'Authorization': 'Bearer ' + getToken(),
  //     }
  //   })
  //     .then(response => {
  //       response?.blob().then(blob => {
  //         let url = window.URL.createObjectURL(blob);
  //         let a = document.createElement('a');
  //         a.href = url;
  //         a.download = "Stock_Data.xlsx";
  //         a.click();
  //         Toast.fire({
  //           icon: 'success',
  //           title: "stock data excel file downloaded."
  //         })
  //       });
  //     })
  // }

  const handleSearch = (type, value) => {
    setActiveSearch(true);
    var obj = {
      searchBy: type,
      searchValue: value,
      warehouseId: warehouse?.warehouseId
    }
    searchStockMutation(obj);
  }

  const handleDownloadExcelFile = async () => {
    setLoading(true);
    await dawnloadExcelFile(warehouse?.warehouseId, "STOCK_DATA");
    setLoading(false);
  }

  const deleteHandler = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: `To delete ${data?.sku} sku, You won't be able to revert this!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        deleteStockdetailsMutation(data?.stockId).then(res => {
          if (res?.data?.status === 0) {
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
          } else {
            Swal.fire("Error", "not deleted " + deleteStcokAction?.error, "error")
          }
        })
      }
    });

  }

  useEffect(() => {
    const init = async () => {
      getStockData();
    }
    init();
    return () => {
      return false;
    }
  }, [pageNumber]);
  return (
    <>
      <div className='container-fluid text-muted'>
        {
          startTour &&
          <GuideTour action={startTour} guideSteps={steps} />
        }

        {
          isZeroWarehouse === true &&
          <>
            <div className='alert alert-info my-5'>You have zero warehouse please create at least one warehouse. <Link to="/dashboard/manage_warehouse">create warehouse</Link></div>
          </>
        }

        {useSelector((state) => state.warehouse.warehouse_is_selected) &&
          <>
            <div className="spaceBetween mt-1">
              <div>
                <div className='alignCenter'>
                  <h2>MANAGE STOCK</h2>
                  <Information info={process.env.REACT_APP_MANAGESTOCK_INFORMATION_MSG} />
                </div>

              </div>
              <div onClick={() => { setStartTour(true) }}>
                <TakeTourBtn />
              </div>
            </div>
            <div className='d-none d-sm-block'>
              <div className='spaceBetween' >
                <div className='left my-2'>
                  <Button className='add-stock-btn' onClick={() => { addStockHandler() }} variant="contained" ><AddCircleOutlineIcon />&nbsp;Add stock</Button>
                  <Button onClick={() => { dispatch(removeWarehouseData); navigate("/dashboard/job_batch_management") }} variant="contained" className="mx-1 importStockDetailsBtn" ><UploadFileIcon />&nbsp; Import stock data</Button>
                  <Button className="addComboData" onClick={() => { navigate("/dashboard/combo_data") }} variant="contained" >+ combo data</Button>
                  <Button disabled={loading} onClick={() => { handleDownloadExcelFile() }} variant="contained" className="mx-1" >{loading ? <BtnLodderIcon action={loading} /> : <CloudDownloadIcon />}&nbsp;Export stock data</Button>
                </div>
                <div className="stockSearch">
                  <SearchStock handleSearch={handleSearch} />
                </div>
              </div>
            </div>

            {/** for mobile screen */}
            <div className='d-block d-sm-none' >
              <div className='left my-2'>
                <Tooltip title="Add new stock entry."><span className='mx-1 text-primary' onClick={() => { addStockHandler() }} ><AddCircleOutlineIcon /></span></Tooltip>
                <Tooltip title="Import stock data."><span className='mx-1 text-primary' onClick={() => { dispatch(removeWarehouseData); navigate("/dashboard/job_batch_management") }}  ><UploadFileIcon /></span></Tooltip>
                <Tooltip title="Combo data." ><span className="mx-1 text-primary addComboData" onClick={() => { navigate("/dashboard/combo_data") }} variant="contained" >+</span></Tooltip>
                <Tooltip title="Export stock data."><span className='mx-1 text-primary' onClick={() => { dawnloadExcelFile(warehouse?.warehouseId, "STOCK_DATA") }}  ><CloudDownloadIcon /></span></Tooltip>
              </div>
              <div className="stockSearch">
                <SearchStock handleSearch={handleSearch} />
              </div>
            </div>




          </>
        }
        <div className="mt-3">

          {useSelector((state) => state.warehouse.warehouse_is_selected) ?
            <div className="table-responsive">

              <div className='mb-1' style={{ display: "flex", justifyContent: "space-between", textAlign: "center" }}>
                <small className='text-muted warehouseName'>Warehouse : <b>{warehouse?.warehouseName}</b></small>&nbsp;&nbsp;
                <small className='mt-1'>
                  <span className='text-muted'><b>change warehouse?</b></span>&nbsp;&nbsp;
                  <Tooltip onClick={() => { window.location.reload() }} className='text-primary pointer mr-3 changeWarehouseBtn' title="Change warehouse">
                    <CachedIcon />
                  </Tooltip>
                </small>
              </div>
              <LinearProressBar value={getStockLoading || searchStockLoading || getStockByWarehouseLoading || deleteStcokAction?.isLoading} />
              <table className="table table-hover mt-2">
                <thead style={{ borderBottom: "1px solid black" }}>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">SKU</th>
                    <th scope="col">AVAILABLE</th>
                    <th scope="col">BLOCKED</th>
                    <th scope="col">SHELF CODE</th>
                    <th scope="col">UPDATED DTIME</th>
                    <th scope="col" className="center">UPDATE RECORD</th>
                  </tr>
                </thead>
                <tbody className='StockTableDetails'>
                  {
                    isActiveSearch ?
                      searchData?.searchStocks?.map((data) => {
                        return (
                          <tr className='' key={data?.channelId}>
                            <td className=''>{data?.stockId}</td>
                            <td className=''>{data?.sku}</td>
                            <td>{data?.quantity}</td>
                            <td></td>
                            <td>{data?.shelfCode}</td>
                            <td>{new Date(data?.updatedDtTime).toLocaleString()}</td>
                            <td className="center">
                              <Button onClick={() => { updateHandler(data) }} variant="contained" color="warning" size='small' >Update</Button>&nbsp;
                              <Button onClick={() => { deleteHandler(data) }} variant="contained" color="error" size='small'>Delete</Button>
                            </td>
                          </tr>

                        )
                      })

                      :
                      stockData?.content?.map((data) => {
                        return (
                          <tr className='' key={data.channelId}>
                            <td className=''>{data.stockId}</td>
                            <td className=''>{data.sku}</td>
                            <td>{data.quantity}</td>
                            <td></td>
                            <td>{data.shelfCode}</td>
                            <td>{new Date(data?.updatedDtTime).toLocaleString()}</td>
                            <td className="center">
                              <Button onClick={() => { updateHandler(data) }} variant="contained" color="warning" size='small' >Update</Button>&nbsp;
                              <Button onClick={() => { deleteHandler(data) }} variant="contained" color="error" size='small'>Delete</Button>
                            </td>
                          </tr>
                        )
                      })}
                </tbody>
              </table>
              <div className="right">
                <Stack spacing={2}>
                  {
                    isActiveSearch === false &&
                    <Pagination color="primary" count={stockData?.totalPages} page={pageNumber} onChange={(e, v) => setPageNumber(v)} />
                  }
                </Stack>
              </div>

            </div>
            :
            <div className='row center my-5'>

              {
                isZeroWarehouse === false &&
                <>
                  <h5 className='text-center my-3'><b>SELECT WAREHOUSE</b></h5>
                  <Information className="mt-2 text-center" info={process.env.REACT_APP_SELECT_WAREHOUSE_MSG} />

                </>
              }

              {

                warehouseData?.map((data) => {
                  return (
                    <div className="col-12  col-sm-6 col-md-3 my-2">
                      <div className="warehouse-card card px-2 card-shadow">

                        <div className="warehouse-card-body card-body px-0 text-center">
                          <h4 className="text-muted mt-2 text-center"> <b>{data.warehouseName}</b></h4>
                          <div className='cardInfoTag'><b>ID : {data.warehouseId}</b></div>
                          <p>{data.warehouseAddress}</p>
                          <p>{data.warehouseContactNo}</p>
                        </div>
                        <div className="card-footer p-0  m-0 ">
                          <Button
                            fullWidth
                            type='submit'
                            onClick={() => { selectWarehouse(data) }}
                            sx={{ my: 1 }}
                            variant="contained"
                          >
                            Select
                          </Button>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          }
        </div>
      </div >

      {/* modal for searching */}
      {/* <Modal
        open={showSearchingPanel}
        onClose={() => { setShowSearchingPannel(!showSearchingPanel) }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography className="text-primary" id="modal-modal-title" variant="h6" component="h2">
            <FontAwesome name="magnifying-glass" className='px-2' />
            SEARCH STOCK
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              fullWidth
              id="searchsku"
              label="SKU"
              autoFocus
              name="searchsku"
            />
            <TextField
              margin="normal"
              fullWidth
              id="searchshelfcode"
              label="Self code"
              name="searchshelfcode"
            />
            <TextField
              margin="normal"
              fullWidth
              id="searchwarehouseid"
              label="Warehouse id"
              autoFocus
              name="searchwarehouseid"
            />
            <FormControl sx={{ minWidth: "100%" }}>
              <InputLabel id="search-status-label">Select status</InputLabel>
              <Select
                labelId="search-status-label"
                id="search-status"
                value={status}
                label="Select state"
                onChange={(e) => setSearchStatus(e.target.value)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={'Active'}>ACTIVE</MenuItem>
                <MenuItem value={'Inavtive'}>INACTIVE</MenuItem>
              </Select>
            </FormControl>

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ mt: 3, mb: 2, py: 2 }}
            >
              Search stock
            </Button>
          </Box>
        </Box>
      </Modal> */}
      {/* searching modal end */}
    </>
  )
}
