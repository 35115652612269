import React from 'react'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
export default function Section1() {
  return (
    <div className=''>
      <div style={{marginTop:"-20px"}} className="row mb-5 ">
        <div className="col-sm-12 text-center col-md-4">
            <div className='p-0' >< AccessAlarmIcon style={{fontSize:"50px", color:"var(--heroBgColor)"}}/></div>
            <div style={{fontSize:"20px",color:"var(--mainColor2)"}}><b>Get Time Back</b></div>
            <p className='text-muted'>Take back your life by streamlining the manual inventory tasks that steal away your hours.</p>
        </div>
        <div className="col-sm-12 text-center col-md-4">
        <div className='p-0' ><QuestionMarkIcon style={{fontSize:"50px", color:"var(--heroBgColor)"}}/></div>

        <div style={{fontSize:"20px",color:"var(--mainColor2)"}}><b>Make Better Decisions</b></div>
            <p className='text-muted'>Gain access to actionable insights, allowing you to make better business decisions.</p>
        </div>
        <div className="col-sm-12 text-center col-md-4">
        <div className='p-0' ><SignalCellularAltIcon  style={{fontSize:"50px", color:"var(--heroBgColor)"}}/></div>

        <div style={{fontSize:"20px",color:"var(--mainColor2)"}}><b>Grow Your Business</b></div>
            <p className='text-muted'>Set the foundation to scale with Manageecom.</p>
        </div>
      </div>
    </div>
  )
}
