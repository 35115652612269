import { Box, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { current } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react'
import { useUpdateRequestData } from '../../../../hooks/useUpdateRequestData';
import { UPDATE_TYPE_PRODUCT } from '../../../../custome/constants';

export default function StepThree({ setStepThreeData, stepThreeData, handleBack, handleNext }) {
    const [bulletPointArray, setBulletPointArray] = useState([]);
    const [bulletPointCounter, setBulletPointCounter] = useState(1);
    const [bulletPointError, setBulletPointError] = useState(null);
    const [bulletPoint,setBulletPoint] = useState(null);
    const updateData = useUpdateRequestData(UPDATE_TYPE_PRODUCT);

    useEffect(()=>{
        if(updateData!=null){
            const a =updateData?.bulletpoints?.split('##')
            a?.map((item,index)=>{
                const obj = {
                    id: index+1,
                    point: item
                }
                setBulletPointArray(current=>[...current,obj]);
            })
            setBulletPointCounter(a?.length+1);
        }
    },[])
    

    const handleSubmitBulletPoint = () => {
        if (bulletPoint === null || bulletPoint?.length <= 0) {
            setBulletPointError("bullet point is required !!");
        } else {
            setBulletPointError(null);
            const obj = {
                id: bulletPointCounter,
                point: bulletPoint
            }
            setBulletPoint('');
            setBulletPointArray(current => [...current, obj])
            setBulletPointCounter(bulletPointCounter + 1);
        }
    }

    const removeBulletPointFromArray = (id) => {
        const newArray = bulletPointArray.filter((point) => point.id != id);
        setBulletPointArray(newArray);
    }

    const handleStepThreeFinalSubmit = () =>{
        setStepThreeData(bulletPointArray);
        handleNext();
    }

    return (
        <div>
            <Box className='' sx={{ mt: 3 }}>
                <Box className="center">
                    <Grid container xs={12} sm={6} md={7} >
                        <Grid item xs={12} my={1}>
                            <TextField
                                autoComplete="given-name"
                                name="productBulletPoint"
                                required
                                error={bulletPointError!=null?true:false}
                                helperText={bulletPointError!=null ? bulletPointError : ''}
                                fullWidth
                                value={bulletPoint}
                                onChange={(e)=>setBulletPoint(e.target.value)}
                                id="productBulletPoint"
                                label="Bullet point"
                                autoFocus
                                sx={{ mb: 2 }}
                            />
                        </Grid>
                        <Grid xs={12}>
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={()=>{handleSubmitBulletPoint()}}
                                sx={{ mt: 1, mr: 1 }}
                            >
                                Add bullet point
                            </Button>
                        </Grid>
                    </Grid>
                </Box>

            </Box>
            <Box className="center" my={2} >
                <Grid container xs={12} sm={8} md={8} >
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>#ID</TableCell>
                                    <TableCell align="left">Bullet Point</TableCell>
                                    <TableCell align="left">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {bulletPointArray?.map((point) => (
                                    <TableRow
                                        key={point.id + "bulletPoint"}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell width={"50px"} component="th" scope="row">
                                            {point.id}
                                        </TableCell>
                                        <TableCell align="left">{point.point}</TableCell>
                                        <TableCell width={"110px"} align="left"><button onClick={() => { removeBulletPointFromArray(point.id) }} className='btn btn-danger btn-sm'>Remove</button></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    color="inherit"
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                >
                    Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button variant="outlined" onClick={handleStepThreeFinalSubmit}>
                    Next
                </Button>
            </Box>
        </div>
    )
}
