import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { SELLERS_BASE_URL } from '../../api/Custom'
import { getToken } from '../../custome/action'

export const marketplaceApi = createApi({
    reducerPath: 'marketplaceApi',
    baseQuery: fetchBaseQuery({ baseUrl: SELLERS_BASE_URL }),
    tagTypes : ['marketplace'],
    endpoints: (builder) => ({
        getMarketplaceDetails: builder.query({
            query: (page) => ({
                url: `/getMarketplaceDetails?page=${page - 1}&limit=10`,
                method: 'GET',
                headers:  {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            providesTags : ['marketplace']
        }),
        
        updateMarketplaceStatus : builder.mutation({
            query : (data) =>({
                url: `/updateMarketplaceStatus`,
                method: 'POST',
                body:data,
                headers:  {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            invalidatesTags:['marketplace']
        }),

        getAllRegisteredMarketplace: builder.query({
            query: () => ({
                url: `/getAllActiveMarketplace`,
                method: 'GET',
                headers:  {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            invalidatesTags:['marketplace']
        }), 

        requestToMarketplace: builder.mutation({
            query : (data) =>({
                url: `/requestToMarketplace`,
                method: 'POST',
                body:data,
                headers:  {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            invalidatesTags:['marketplace']
        }),
       
    })
})

export const { useGetMarketplaceDetailsQuery, useUpdateMarketplaceStatusMutation, useGetAllRegisteredMarketplaceQuery, useRequestToMarketplaceMutation} = marketplaceApi