import { createSlice } from "@reduxjs/toolkit";
const warehouseInitialState = {
    warehouse_is_selected : false,
    warehouse: null,
}

const warehouseSlice = createSlice({
    name:'warehouse',
    initialState:warehouseInitialState,
    reducers : {
        saveWarehouseData : (state,action)=>{
            return state ={
                warehouse_is_selected:true,
                warehouse : action.payload
            }
        },
        removeWarehouseData : (state)=>{
            return state = {
                warehouse_is_selected : false,
                warehouse : null,
            }
        }
    }
})

export const {saveWarehouseData, removeWarehouseData} = warehouseSlice.actions;
export default warehouseSlice.reducer;