import React, { useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import InfoIcon from '@mui/icons-material/Info';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useGetMarketplaceDetailsQuery, useUpdateMarketplaceStatusMutation } from '../../../hooks/RTK_Service/marketplaceApi';
import { apiErrorManagemnt2 } from '../../../api/Custom';
import { IconButton, Modal, Switch, Tooltip, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import RegisteredMarketplaces from './RegisteredMarketplaces';
import GuideTour from '../../../components/guide/GuideTour';
import { steps } from './guideSteps';
import HelpTooltip from '../../../components/custom/HelpTooltip';
import Information from '../../../components/custom/Information';
import TakeTourBtn from '../../../components/custom/TakeTourBtn';
import { Button, CircularProgress } from '@mui/material';
import axios from 'axios';
import PolicyIcon from '@mui/icons-material/Policy';



const modalStyle = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    backgroundColor: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '16px',
};


export default function Marketplace() {
    const [pageNumber, setPageNumber] = useState(1);
    const [checked, setChecked] = useState(false);
    const [startTour, setStartTour] = useState(false);
    const marketplace = useGetMarketplaceDetailsQuery(pageNumber)

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [policy, setPolicy] = useState('');



    const [updateMarketplace, { isLoading: marketplaceIsLoading, isError: isMarketplaceError, error: marketplaceError }] = useUpdateMarketplaceStatusMutation();

    if (marketplace?.isError) {
        apiErrorManagemnt2(marketplace.error)
    }

    const handleUpdateMarketplaceStatus = (id, status) => {
        var value = "";
        if (status) {
            value = "Y"
        } else {
            value = "N"
        }
        const obj = {
            status: value,
            marketplaceId: id
        }
        updateMarketplace(obj).then(res => {
            if (res?.data?.status === 0) {
                Swal.fire({
                    title: "Success",
                    text: res?.data?.message,
                    icon: "success"
                });
            }
        })
    };

    const handleOpen = async (url) => {
        setLoading(true);
        setOpen(true);
        try {
            const response = await axios.get(url + "/getPolicy/marketplace_policy_for_sellers");
            setPolicy(response.data);
        } catch (error) {
            setPolicy('Failed to fetch policy description');
            console.error('Error fetching policy description:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (

        <div className='container-fluid'>
            {
                startTour &&
                <GuideTour action={startTour} guideSteps={steps} />
            }

            <div className='right mt-1' onClick={() => { setStartTour(true) }}>
                <TakeTourBtn />
            </div>
            <div className='row mt-2'>
                <div className="activeMarketplace col-12 col-md-6">
                    <div>
                        <TableContainer component={Paper} sx={{ height: "90vh", overflow: "auto", overflowX: "hidden" }}>
                            <div className='spaceBetween'>
                                <Typography sx={{ mt: 1, ml: 1 }} component="h1" variant="h6">
                                    Manage Marketplace
                                </Typography>
                                <HelpTooltip className="mx-1" msg={process.env.REACT_APP_MANAGEMARKETPLACE_TOOLTIP_MSG} />
                            </div>
                            <>
                                <Information info={process.env.REACT_APP_MANAGEMARKETPLACE_INFORMATION_MSG} />
                            </>
                            <Table md={{ minWidth: 650 }} aria-label="simple table">
                                {/* <TableHead>
                                    <TableRow>
                                        <TableCell>#ID</TableCell>
                                        <TableCell align="left">COMPANY NAME</TableCell>
                                        <TableCell align="left">COMPANY EMAIL</TableCell>
                                        <TableCell align="left">COMPANY CONTACT</TableCell>
                                        <TableCell align="left">COMPANY URL</TableCell>
                                        <TableCell align="left">STATUS</TableCell>
                                    </TableRow>
                                </TableHead> */}
                                <TableBody>
                                    {marketplace?.data?.marketplace?.content?.map((row) => (
                                        <TableRow
                                            hover
                                            className="activeMarketplaceInfo"
                                            key={row?.marketplaceId}
                                            sx={{ '&:last-child td, &:last-child th': { borderBottom: "1px solid gray" } }}
                                        >
                                            <TableCell>

                                                <span><b>Name :</b> {row?.companyName}</span><br />
                                                <span><b>Email : </b>{row?.companyEmail}</span><br />
                                                <span><b>Contact : </b>{row?.companyContact}</span><br />
                                                {/* <span>{row?.companyUrl}</span> */}
                                                <span>
                                                    <IconButton color="primary" onClick={() => handleOpen(row?.apiEndPoint)}>
                                                        <PolicyIcon />
                                                    </IconButton>

                                                </span>

                                            </TableCell>
                                            <TableCell>
                                                <span><b>ID : </b> {row?.marketplaceId}</span><br />
                                                <span className="amStatus"><b>Status : </b> {row?.isEnabled === 'Y' ? 'Active' : 'Inactive'}</span><br />
                                            </TableCell>


                                            {/* <TableCell align="left">{row?.companyName}</TableCell>
                                            <TableCell align="left">{row?.companyEmail}</TableCell>
                                            <TableCell align="left">{row?.companyContact}</TableCell>
                                            <TableCell align="left">{row?.companyUrl}</TableCell>
                                    */}
                                            <TableCell align="bottom" >

                                                {
                                                    row?.status === "S_REQUESTED" ?
                                                        <p className="text-warning">Waiting for acceptance…</p>
                                                        :
                                                        <Switch
                                                            className="amToggleBtn"
                                                            defaultChecked={row?.isEnabled === 'Y' ? true : false}
                                                            onChange={(e) => { handleUpdateMarketplaceStatus(row?.marketplaceId, e.target.checked) }}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                }


                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>

                            </Table>
                        </TableContainer>
                    </div>
                </div>


                <div className="registerdMarketplace col-12 col-md-6">
                    <div>
                        <RegisteredMarketplaces marketplace={marketplace} />
                    </div>
                </div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="marketplace-policy-title"
                    aria-describedby="marketplace-policy-description"
                    style={{ mt: 5 }}
                >
                    <div style={modalStyle}>
                        <Typography className="mb-2" id="marketplace-policy-title" variant="h6">
                            <PolicyIcon /> Marketplace Policy
                        </Typography>
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <Typography id="marketplace-policy-description">
                                <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                    {policy.split("##").map(point => {
                                        return <div className="my-1">{point}</div>
                                    })}
                                </div>
                            </Typography>
                        )}
                    </div>
                </Modal>


            </div>
        </div>
    )
}
