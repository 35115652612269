// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-div{
    position: fixed;
    top:0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color:rgba(128, 128, 128, 0.231);
    display: flex;
    justify-content: center;
    align-items: center;
}
.loadder{
    color: rgb(86, 86, 86);
}`, "",{"version":3,"sources":["webpack://./src/components/loader/loader.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,KAAK;IACL,OAAO;IACP,aAAa;IACb,WAAW;IACX,2CAA2C;IAC3C,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,sBAAsB;AAC1B","sourcesContent":[".loader-div{\n    position: fixed;\n    top:0;\n    left: 0;\n    height: 100vh;\n    width: 100%;\n    background-color:rgba(128, 128, 128, 0.231);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n.loadder{\n    color: rgb(86, 86, 86);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
