// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard_card{
    height: 150px;
}
.dash-icon-card{
    flex:1 1;
    font-size: 35px;
    margin-top: 22px;
    padding-right: 8px; 

 }
 .dash-text-card{
    flex:5 1;
 }
@media (min-width: 1400px) {
    .dash-icon-card{
        flex:2 1;
        font-size: 45px; 
     }
}

@media (max-width: 500px) {
    .dashboard_card{
        height: 100px;
    }
}



`, "",{"version":3,"sources":["webpack://./src/dashboardPages/dashboard/dashboard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,QAAM;IACN,eAAe;IACf,gBAAgB;IAChB,kBAAkB;;CAErB;CACA;IACG,QAAM;CACT;AACD;IACI;QACI,QAAM;QACN,eAAe;KAClB;AACL;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".dashboard_card{\n    height: 150px;\n}\n.dash-icon-card{\n    flex:1;\n    font-size: 35px;\n    margin-top: 22px;\n    padding-right: 8px; \n\n }\n .dash-text-card{\n    flex:5;\n }\n@media (min-width: 1400px) {\n    .dash-icon-card{\n        flex:2;\n        font-size: 45px; \n     }\n}\n\n@media (max-width: 500px) {\n    .dashboard_card{\n        height: 100px;\n    }\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
