// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warehouse-card{
    position: relative;
    overflow: hidden;
}
.warehouse-card-body{
    min-height: 70px;

}
.cardInfoTag {
    position: absolute;
    right: -50px;
    width:50px;
    padding:2px ;
    color:aliceblue;
    top:0px;
    background-color: rgba(32, 32, 32, 0.514);
    transition: 1s all;
    
  }

.warehouse-card:hover .cardInfoTag{
    right:0px;
    top:0px; 
}`, "",{"version":3,"sources":["webpack://./src/dashboardPages/master/manage_stock/manageStock.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,gBAAgB;;AAEpB;AACA;IACI,kBAAkB;IAClB,YAAY;IACZ,UAAU;IACV,YAAY;IACZ,eAAe;IACf,OAAO;IACP,yCAAyC;IACzC,kBAAkB;;EAEpB;;AAEF;IACI,SAAS;IACT,OAAO;AACX","sourcesContent":[".warehouse-card{\n    position: relative;\n    overflow: hidden;\n}\n.warehouse-card-body{\n    min-height: 70px;\n\n}\n.cardInfoTag {\n    position: absolute;\n    right: -50px;\n    width:50px;\n    padding:2px ;\n    color:aliceblue;\n    top:0px;\n    background-color: rgba(32, 32, 32, 0.514);\n    transition: 1s all;\n    \n  }\n\n.warehouse-card:hover .cardInfoTag{\n    right:0px;\n    top:0px; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
