import { createSlice } from "@reduxjs/toolkit";

const initial = {
    user:null 
}

const userData = createSlice({
    name: "user",
    initialState: initial,
    reducers: {
        setUserDetails: (state, action) => {
            return state = {
                user: action.payload.user
            }
        },
        removeUserDetails:(state)=>{
            return state ={
                user: null
            }
        }
    }
})

export const { setUserDetails,removeUserDetails }  = userData.actions;
export default userData.reducer;