import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CircularProgress from '@mui/material/CircularProgress';
import stockTplateXl from '../../../files/xls/Stock_Adjustment_Template.xlsx'
import productVariationTplateXL from '../../../files/xls/Product_Variations_Adjustment_Template.xlsx'
import productTplateXL from '../../../files/xls/Product_Adjustment_Template.xlsx'
import { useUploadExcelFileMutation } from '../../../hooks/RTK_Service/jobBatchApi';
import { apiErrorManagemnt2 } from '../../../api/Custom';
import { dawnloadProductErrorExcelFile } from '../../../api/JobManagement';
import mapProductTplateXL from '../../../files/xls/Channel_Product_Template.xlsx'
import { useGetChannelsMutation } from '../../../hooks/RTK_Service/channel';
import DownloadIcon from '@mui/icons-material/Download';

const jbCategoryList = [
    {
        id: 'inventory1',
        value: "Inventory",
        displayName: "INVENTORY"
    }
]

export default function Job__batch_Management() {
    const [jb_category, setJbCategory] = useState(null);
    const [jb_subcategory, setJbSubCategory] = useState(null);
    const [excelFile, setExcelFile] = useState(null);
    const [listingArray, setListingArray] = useState([]);
    const [channelName, setChannelName] = useState("");

    const [uploadExcelFile, { isError, error, isSuccess, isLoading }] = useUploadExcelFileMutation();
    const [getChannelMutation, action] = useGetChannelsMutation();

    const handleJbSubCategory = (name) => {
        setJbSubCategory(name);
        var temp = [];
        if (name === "FLIPKART_ACTIVE_LISTING") {
            getChannelMutation({ page: 1, limit: 100 }).then(res => {
                res?.data?.map(item => {
                    if (item?.channel === "FLIPKART")
                        temp.push(item?.channelName);
                })
                setListingArray(temp);
            })
        }
    }


    const handleJobBatcManagementSubmit = () => {
        const data = new FormData();
        data.append("excelFile", excelFile);
        data.append("category", jb_category);
        data.append("subCategory", jb_subcategory);
        data.append("channelName", channelName);

        if (jb_category === 'Inventory' && (jb_subcategory === 'STOCK' || jb_subcategory === 'PRODUCT' || jb_subcategory === 'CHANNEL_PRODUCT' || jb_subcategory === 'FLIPKART_ACTIVE_LISTING' || jb_subcategory === 'PRODUCT_VARIATION')) {
            if (jb_subcategory === 'FLIPKART_ACTIVE_LISTING') {
                if (channelName === ""){
                    Swal.fire("Channel Not Selected", "please select the flipkart channel", "error");
                    return;
                }
            }

            uploadExcelFile(data).then((res) => {
                if (res?.error?.data?.status === -1) {
                    apiErrorManagemnt2(res?.error)
                }
                if (res?.error) {
                    if (res?.error?.data?.message)
                        Swal.fire("Some thing wrong!!", res?.error?.data?.message, "error");
                    else {
                        Swal.fire("Some thing wrong!!", "Refresh your page, and then try again..", "error");
                    }
                }
                if (res?.data?.status === 0) {
                    Swal.fire("File upload success", "your file data is save successfully, <b>if data not display in table then refresh the page</b>.", "success");
                }
                if (res?.data?.status === 1) {
                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            confirmButton: "btn btn-success m-2 p-2",
                            cancelButton: "btn btn-danger m-2 p-2"
                        },
                        buttonsStyling: false
                    });
                    swalWithBootstrapButtons.fire({
                        title: "JOP STATUS",
                        html: res?.data?.message + ",<br/> <b>if successfully inserted rows data not display in table then refresh the page</b>.",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Download error excel file!",
                        cancelButtonText: "cancel",
                        reverseButtons: true
                    }).then((result) => {
                        if (result.isConfirmed) {
                            dawnloadProductErrorExcelFile(res?.data?.jobTypeId);
                        } else if (
                            /* Read more about handling dismissals below */
                            result.dismiss === Swal.DismissReason.cancel
                        ) {
                            swalWithBootstrapButtons.fire({
                                title: "Information",
                                html: `Your all data is not successfully loaded, some rows contain the errors please <a>download the error excel file </a> and fix the errors and upload again this file.`,
                                icon: "info"
                            });
                        }
                    });


                }
            })

        } else {
            Swal.fire({
                icon: 'error',
                toast: true,
                position: 'bottom-right',
                text: 'please sealect mandatory(*) fields',
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true
            })
        }
    }




    return (
        <>
            <div className='text-center'>
                <div className="text-muted">
                    <h2>Job/Batch Management</h2>
                </div>
            </div>
            <form  >

                <Box className='center' noValidate sx={{ mt: 3 }}>

                    <Grid container className='center' xs={12} sm={6} md={5} >
                        <Grid xs={12} >
                            <FormControl sx={{ minWidth: "100%", }}>
                                <InputLabel id="jbcallabel">Select Category *</InputLabel>
                                <Select
                                    labelId="jbcallabel"
                                    id="jbcat"
                                    value={jb_category}
                                    label="Channel name"
                                    onChange={event => { setJbCategory(event.target.value) }}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {
                                        jbCategoryList?.map((item) => <MenuItem key={item.id} value={item.value}>{item.displayName}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        {jb_category === 'Inventory' &&
                            <Grid container xs={12} mt={3}>
                                <FormControl sx={{ minWidth: "100%", }}>
                                    <InputLabel id="jbsubcatlable">Select type</InputLabel>
                                    <Select
                                        labelId="jbsubcatlable"
                                        id="jbsubcat"
                                        value={jb_subcategory}
                                        label="Channel name"
                                        onChange={event => { handleJbSubCategory(event.target.value) }}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem key='stckAdujustment' value="STOCK">Stock Adjustment</MenuItem>
                                        <MenuItem key='ptrAdujustment' value="PRODUCT">Product Adjustment</MenuItem>
                                        <MenuItem key='ptrVariAdujustment' value="PRODUCT_VARIATION">Product Variation Adjustment</MenuItem>
                                        <MenuItem key='mpPtrkAdujustment' value="CHANNEL_PRODUCT">Channel Product Adjustment</MenuItem>
                                        <MenuItem key='mpPtrkAdujustment' value="FLIPKART_ACTIVE_LISTING">Upload Flipkart Active Listing</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                        }

                        {jb_subcategory === 'FLIPKART_ACTIVE_LISTING' &&
                            <Grid container xs={12} mt={3}>
                                <FormControl sx={{ minWidth: "100%", }}>
                                    <InputLabel id="jbsubcatlable">Select channel</InputLabel>
                                    <Select
                                        labelId="jbsubcatlable"
                                        id="jbsubcat"
                                        value={channelName}
                                        label="Channel name"
                                        onChange={event => { setChannelName(event.target.value) }}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {listingArray?.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
                        }

                        <Grid item xs={12} mt={3} >
                            <TextField
                                autoComplete="given-name"
                                name="excelFile"
                                required
                                fullWidth
                                id="excelFile"
                                type='file'
                                onChange={event =>
                                    setExcelFile(event.target.files[0])
                                }
                                sx={{ mb: 2 }}

                            />
                        </Grid>

                        {jb_subcategory === 'STOCK' &&
                            <Grid item xs={12} mt={3} >
                                <a href={stockTplateXl} download="Stock_Adjustment_Template" target='_blank'>

                                    <Button
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, py: 2, }}
                                        color='success'

                                    >
                                        <DownloadIcon />&nbsp; Dawnload Stock Template
                                    </Button>
                                </a>
                            </Grid>
                        }
                        {jb_subcategory === 'PRODUCT' &&
                            <Grid item xs={12} mt={3} >
                                <a href={productTplateXL} download="Product_Adjustment_Template" target='_blank'>

                                    <Button
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, py: 2, }}
                                        color='success'
                                    >
                                        <DownloadIcon />&nbsp; Dawnload Product Template
                                    </Button>
                                </a>
                            </Grid>
                        }

                        {jb_subcategory === 'PRODUCT_VARIATION' &&
                            <Grid item xs={12} mt={3} >
                                <a href={productVariationTplateXL} download="Product_Variation_Adjustment_Template" target='_blank'>

                                    <Button
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, py: 2, }}
                                        color='success'
                                    >
                                        <DownloadIcon />&nbsp; Dawnload Product Variation Template
                                    </Button>
                                </a>
                            </Grid>
                        }

                        {jb_subcategory === 'CHANNEL_PRODUCT' &&
                            <Grid item xs={12} mt={3} >
                                <a href={mapProductTplateXL} download="Mapped_Product_Template" target='_blank'>

                                    <Button
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, py: 2 }}
                                        color='success'
                                    >
                                        <DownloadIcon />&nbsp; Dawnload Channel Product Template
                                    </Button>
                                </a>
                            </Grid>
                        }

                        <Grid item xs={12} mt={3} >
                            <Button
                                fullWidth
                                onClick={() => { handleJobBatcManagementSubmit() }}
                                component="label"
                                variant="contained"
                                sx={{ mb: 2, py: 2, }}
                                startIcon={
                                    isLoading ?
                                        <CircularProgress
                                            variant="indeterminate"
                                            disableShrink
                                            size={20}
                                            thickness={4}
                                            color="inherit"

                                        />
                                        : <CloudUploadIcon />}>

                                Submit file
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </>
    )
}
