import { createSlice } from "@reduxjs/toolkit";

const initial = {
    updateState: false,
    updateType: null,
    data: null
}

const updateData = createSlice({
    name: "updateData",
    initialState: initial,
    reducers: {
        setUpdateDetails: (state, action) => {
            return state = {
                updateState: true,
                updateType: action.payload.type,
                data: action.payload.data
            }
        },
        removeUpdateDetails:(state)=>{
            return state ={
                updateState: false,
                updateType: null,
                data: null
            }
        }
    }
})

export const {setUpdateDetails,removeUpdateDetails}  = updateData.actions;
export default updateData.reducer;