import Swal from "sweetalert2";
import { getToken, logout } from "../custome/action";
export const SELLERS_BASE_URL =process.env.REACT_APP_SELLER_BASE_URL;   //"http://localhost:8199/manageecom/api"
export const BASE_URL =process.env.REACT_APP_BASE_URL //"http://localhost:8199"   ;
// console.log("seller : "+process.env.REACT_APP_SELLER_BASE_URL)
// console.log("base : "+process.env.REACT_APP_BASE_URL)
// console.log("image base Url :" +process.env.REACT_APP_MANAGEECOM_BASE_URL);


export const apiErrorManagemnt = (error) => {
    console.error(error);
    if(error?.message==='Network Error'){
        alert(error?.message)
        logout();
    }else if(error?.status===401){
        Swal.fire(
            "<span style='color:red'>Error happen!!</span>",
            "Unauthorized access !!",
            'error'
        )
    }else{
        Swal.fire(
            "<span style='color:red'>Error happen!!</span>",
            error?.message + ", <b>" + error?.response?.data?.message + "</b>",
            'error'
        )
    }
}
export const apiErrorManagemnt2 = (error) => {
    console.error(error);
    if(error?.message==='Network Error'){
        alert(error?.message)
        logout();
    }else{
        Swal.fire(
            "<span style='color:red'>Error happen!!</span>",
            "<b>" + error?.data?.message + "</b>",
            'error'
        )
    }
}


  