import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD_BASE_PATH, MANAGE_STOCK_PATH, MANAGE_VARIATION_PATH, UPDATE_REMOVE_VARIATION_PATH, UPDATE_TYPE_VARIATION } from '../../../custome/constants';
import { useAddUpdateProductVariationsMutation, useGetProductVariationsQuery } from '../../../hooks/RTK_Service/productVariationsApi';
import { Chip, Pagination, Stack } from '@mui/material';
import Loader from '../../../components/loader/Loader';
import { apiErrorManagemnt2 } from '../../../api/Custom';
import { removeUpdateDetails, setUpdateDetails } from '../../../store/updateData';
import Swal from 'sweetalert2';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


const ManageVariation = () => {
    const [pageNumber, setPageNumber] = useState(1);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const warehouseIsSelected = useSelector((state) => state.warehouse.warehouse_is_selected) ? false : true;
    const [addUpdateProductVariation, { isError: variationIsError, error: variationError }] = useAddUpdateProductVariationsMutation();

    //if warehouse not seleted then open warehouseletion panal
    if (warehouseIsSelected) {
        navigate(DASHBOARD_BASE_PATH + MANAGE_STOCK_PATH + "?tpath=" + MANAGE_VARIATION_PATH);
    }

    const warehouse = useSelector((state) => state.warehouse.warehouse)
    const dataObj = {
        page: pageNumber,
        warehouseId: warehouse?.warehouseId
    }
    const { isLoading, data, isError, error, isFetching, isSuccess } = useGetProductVariationsQuery(dataObj);

    if (isLoading || isFetching) {
        return <Loader action={true} />
    }

    if (isError) {
        if (warehouseIsSelected == false)
            apiErrorManagemnt2(error)
    }
    if (variationIsError) {
        apiErrorManagemnt2(variationError)
    }

    const updateVariation = (data) => {
        dispatch(removeUpdateDetails());
        dispatch(setUpdateDetails({ type: UPDATE_TYPE_VARIATION, data: data }))
        navigate(DASHBOARD_BASE_PATH + UPDATE_REMOVE_VARIATION_PATH);
    }

    const deleteVariation = (data) => {
        try {
            var obj = {
                action: 'DELETE',
                productVariationId: data?.productVariationId,
                warehouseId: warehouse?.warehouseId,
                variations: "{}"
            };

            Swal.fire({
                title: "Do you want to delete variation '" + data?.variationName + "'?",
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: "YES",
                denyButtonText: `NO`
            }).then((result) => {
                if (result.isConfirmed) {
                    addUpdateProductVariation(obj).then(response => {
                        if (response?.data?.status == 0) {
                            Swal.fire("Success", response?.data?.message, "success");
                        } else {
                            Swal.fire("Error!!", response?.data?.message, "error");
                        }
                    })
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className='container-fluid'>
            {data &&
                <div className="table-responsive">
                    <table className="table table-hover mt-2">
                        <thead style={{ borderBottom: "1px solid black" }}>
                            <tr>
                                <th scope="col">ID</th>
                                <th scope="col">NAME</th>
                                <th scope="col">JSON</th>
                                <th scope='col'>Actions</th>
                            </tr>
                        </thead>
                        <tbody className='productTable'>
                            {
                                data?.productVariations?.content.map((pv) => {
                                    return (
                                        <tr key={pv?.productVariationId}>
                                            <td >{pv?.productVariationId}</td>
                                            <td >{pv?.variationName}</td>
                                            <td >{pv?.variations}</td>
                                            <td>
                                                <Stack direction="row" spacing={1}>
                                                    <EditIcon style={{ "cursor": "pointer" }} className='text-warning mx-1' onClick={() => { updateVariation(pv) }} />
                                                    <DeleteIcon style={{ "cursor": "pointer" }} className='text-danger mx-1' onClick={() => { deleteVariation(pv) }} />
                                                </Stack>
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                    <div className="right">
                        <Stack spacing={2}>
                            {
                                <Pagination color="primary" count={data?.productVariations?.totalPages} page={pageNumber} onChange={(e, v) => setPageNumber(v)} />
                            }
                        </Stack>
                    </div>
                </div>
            }
        </div>
    );

};

export default ManageVariation;
