import React, { useState } from 'react'
import logo from '../../files/imgs/manageEcom-logo.png'
import Avatar from '@mui/material/Avatar';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LanguageIcon from '@mui/icons-material/Language';

import './header.css'
import { Link} from 'react-router-dom'

export default function Header() {
    //bg-body-tertiary
    const [headeBackgroundColor, setHeaderBackgroundColor] = useState('');
    const [headerTextColor, setHeaderTextColor] = useState('text-light');
    const [menuBackground, setMenuBackground] = useState('')

    const changeNavbarColor = () => {
        if (window.scrollY >= 30) {
            setHeaderBackgroundColor("bg-body-tertiary");//white bg
            setHeaderTextColor("text-dark")
        }
        else {
            setHeaderBackgroundColor("");
            setHeaderTextColor("text-light")
        }
    };
    window.addEventListener('scroll', changeNavbarColor);

    return (
        <div>
            <nav className={"fixed-top navbar navbar-expand-lg " + headeBackgroundColor}>
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">
                        <img src={logo}  className='logo' alt="manageEcom" />
                    </a>
                    <button onClick={() => { setMenuBackground("bg-body-tertiary"); setHeaderTextColor('text-dark') }} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={"collapse navbar-collapse " + menuBackground} id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link to="/" className={"nav-link active " + headerTextColor} aria-current="page">HOME</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={"nav-link " + headerTextColor} to="/about">ABOUT</Link>
                            </li>
                            {/* <li className="nav-item">
                                <a className={"nav-link " + headerTextColor} aria-disabled="true">PLATFORM</a>
                            </li> */}
                            <li className="nav-item">
                                <Link to="/service" className={"nav-link " + headerTextColor} aria-disabled="true">SERVICES</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/pricing" className={"nav-link " + headerTextColor} aria-disabled="true">PRICING</Link>
                            </li>
                            {/* <li className="nav-item">
                                <a className={"nav-link " + headerTextColor} aria-disabled="true">GST</a>
                            </li> */}
                            <li className="nav-item">
                                <Link to="/contact" className={"nav-link " + headerTextColor} aria-disabled="true">CONTACT</Link>
                            </li>
                        </ul>
                        <div className="d-flex left-section" >
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <Link to="/login" className={"nav-link active l-btn " + headerTextColor} aria-current="page">LOGIN</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/register" className={"nav-link active r-btn " + headerTextColor} aria-current="page" >REGISTER</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>

            {/* fixed buttons */}
            <div className="siteTabs">
                <div className="number-tab site-tab">'
                    <Avatar sx={{ bgcolor: 'var(--mainColor2)' }}>
                        <LocalPhoneIcon />
                    </Avatar>
                    <a style={{textDecoration:"none"}} className='text-light' href='tel:7718895734'><p className='number'> +91 7718895734</p></a>
                </div>
                <div className="whatsapp site-tab">'
                    <Avatar sx={{ bgcolor: 'var(--mainColor2)' }}>
                        <LanguageIcon />
                    </Avatar>
                    <a style={{textDecoration:"none"}} href='mailTo:support@nuvio.in' className="text-light" ><p className='number'> support@nuvio.in</p></a>

                </div>
            </div>
        </div>

    )
}
