// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-right{
    margin: 10% 0% ;
    
}
h1{
    text-transform: uppercase;
    font-size: 50px;
    font-weight: bold;
    line-height: 1.3em;
}
.color-s1{
    box-sizing: border-box;
    background-color: var(--mainColor);
    color: white;
    padding: 0px 5px;
}
.color-s2{
    color:var(--mainColor)
}
.separator-div{
    background-color: var(--mainColor);
    height: 5px;
    width:100%;
    margin: 10px 0px;
}
.para{
    margin-top: 40px;
    font-size: 17px;
}
.shedule-btn{
    margin-top: 20px;
    background-color:var(--mainColor2);
    color: white;
    text-transform: uppercase;
    border-radius: 30px;
    border:none;
    font-weight: bold;
    padding: 13px 50px;

}

.sheduler-wrapper{
    margin-top: 130px;
}
@media (min-width: 1500px) {
    
  }`, "",{"version":3,"sources":["webpack://./src/components/sheduler/sheduler.css"],"names":[],"mappings":"AAAA;IACI,eAAe;;AAEnB;AACA;IACI,yBAAyB;IACzB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,sBAAsB;IACtB,kCAAkC;IAClC,YAAY;IACZ,gBAAgB;AACpB;AACA;IACI;AACJ;AACA;IACI,kCAAkC;IAClC,WAAW;IACX,UAAU;IACV,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,gBAAgB;IAChB,kCAAkC;IAClC,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,WAAW;IACX,iBAAiB;IACjB,kBAAkB;;AAEtB;;AAEA;IACI,iBAAiB;AACrB;AACA;;EAEE","sourcesContent":[".s-right{\n    margin: 10% 0% ;\n    \n}\nh1{\n    text-transform: uppercase;\n    font-size: 50px;\n    font-weight: bold;\n    line-height: 1.3em;\n}\n.color-s1{\n    box-sizing: border-box;\n    background-color: var(--mainColor);\n    color: white;\n    padding: 0px 5px;\n}\n.color-s2{\n    color:var(--mainColor)\n}\n.separator-div{\n    background-color: var(--mainColor);\n    height: 5px;\n    width:100%;\n    margin: 10px 0px;\n}\n.para{\n    margin-top: 40px;\n    font-size: 17px;\n}\n.shedule-btn{\n    margin-top: 20px;\n    background-color:var(--mainColor2);\n    color: white;\n    text-transform: uppercase;\n    border-radius: 30px;\n    border:none;\n    font-weight: bold;\n    padding: 13px 50px;\n\n}\n\n.sheduler-wrapper{\n    margin-top: 130px;\n}\n@media (min-width: 1500px) {\n    \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
