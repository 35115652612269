import React, { useState } from 'react'
import { useGetShippingPartnerDataQuery } from '../../../hooks/RTK_Service/shippingPartnerApi';
import { ITHINK_LOGISTICS } from '../../../custome/constants';
import { apiErrorManagemnt2 } from '../../../api/Custom';
import { Avatar, Pagination, Stack } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function IthinkLogisticTable({ setOpenTable, setShippingPartner }) {
    const [pageNumber, setPageNumber] = useState(1);
    const ithinkLogisticData = useGetShippingPartnerDataQuery({ page: pageNumber, limit: 10, type: ITHINK_LOGISTICS })
    if (ithinkLogisticData?.isError) {
        apiErrorManagemnt2(ithinkLogisticData?.error)
    }

    return (
        <div>
            <div className="spaceBetween mt-1">
                <div>
                    <div className='alignCenter d-flex'>
                        <Avatar className="pointer" onClick={() => { setOpenTable(null); setShippingPartner(null) }} sx={{ width: 31, height: 31, bgcolor: "paper", mr: 1 }}>
                            <ArrowBackIcon />
                        </Avatar>
                        <h2>ITHINK LOGISTICS</h2>
                    </div>
                </div>
            </div>
            <div className='table-responsive'>
                <table className="table table-hover mt-2">
                    <thead style={{ borderBottom: "1px solid black" }}>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">WAREHOUSE ID</th>
                            <th scope="col">ITHINK LOGISTICS KEY</th>
                            <th scope="col">ITHINK LOGISTICS TOKEN</th>
                            <th scope="col">SP WAREHOUSE ID</th>
                            <th scope="col">CREATED TIME</th>
                            <th scope="col">UPDATE</th>
                        </tr>
                    </thead>
                    <tbody className='StockTableDetails'>
                        {

                            ithinkLogisticData.data?.data?.content?.map((data) => {
                                return (
                                    <tr className='' key={data?.ithinkLogisticsSettingsId}>
                                        <td className=''>{data?.ithinkLogisticsSettingsId}</td>
                                        <td className=''>{data?.shippingPartner?.warehouseId}</td>
                                        {
                                            data?.shippingPartner?.shippingPartnerCredentialType=="SELF"?
                                        <> 
                                        <td>{data?.ithinkLogisticKey}</td>
                                        <td>{data?.ithinkLogisticToken}</td>
                                        </>
                                        :
                                        <> 
                                        <td>{data?.ithinkLogisticKey?.substring(0, 6) + 'X'.repeat(17)}</td>
                                        <td>{data?.ithinkLogisticToken?.substring(0, 6) + 'X'.repeat(17)}</td>
                                        </>
                                        }
                                        <td>{data?.ithinkLogisticWarehouseId}</td>
                                        <td>{data?.shippingPartner?.createdDtTime}</td>
                                        <td className="center"><button className='btn btn-sm btn-outline-warning'>Update</button></td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <div className="right">
                    <Stack spacing={2}>
                        {
                            <Pagination color="primary" count={ithinkLogisticData?.data?.totalPages} page={pageNumber} onChange={(e, v) => setPageNumber(v)} />
                        }
                    </Stack>
                </div>
            </div>

        </div>
    )
}
