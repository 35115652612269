import React from 'react'
import Information from '../../../components/custom/Information'
import {Box, Tabs, Tab, Typography, Autocomplete, TextField } from '@mui/material';
import PropTypes from 'prop-types';

import { useState } from 'react';
import OrdersTable from './OrdersTable';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const options = ['ALL', 'AMAZON',"FLIPKART"];

export default function ChannelOrders() {
    const [value, setValue] = React.useState(0);
    const [channel, setChannel] = useState("ALL");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className='container-fluid'>
            <div className='text-muted'>
                <div className='alignCenter'>
                    <h2>CHANNEL ORDERS</h2>
                    <Information info="Channel orders show here" />
                </div>
                <div className='spaceBetween mt-2 mt-sm-1'>
                    <div></div>
                    <div>
                        <Autocomplete
                            value={channel}
                            onChange={(event, newValue) => {
                                setChannel(newValue);
                            }}
                            id="controllable-states-demo"
                            options={options}
                            size='small'
                            sx={{ width: '340px' }}
                            renderInput={(params) => <TextField {...params} label="Channel Search" />}
                        />

                    </div>
                </div>
                <div>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example" >
                                <Tab label="New" {...a11yProps(0)} />
                                <Tab label="Packing" {...a11yProps(1)} />
                                <Tab label="Packed" {...a11yProps(2)} />
                                <Tab label="handover" {...a11yProps(3)} />
                                <Tab label="InTransit" {...a11yProps(4)} />
                                <Tab label="Delivered" {...a11yProps(5)} />
                                <Tab label="Return" {...a11yProps(6)} />
                                <Tab label="Cancelled" {...a11yProps(7)} />
                                <Tab label="Failed" {...a11yProps(8)} />
                                <Tab label="Pending" {...a11yProps(9)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <OrdersTable status="UNSHIPPED" channel={channel} />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            {/* <OrdersTable status="PENDING" channel={channel} /> */}
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>
                            <OrdersTable status="PACKED" channel={channel} />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={3}>
                            <OrdersTable status="Shipped" channel={channel} />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={4}>
                            intransit
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={5}>
                            <OrdersTable status="DELIVERED" channel={channel} />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={6}>
                            <OrdersTable status="RETURNED" channel={channel} />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={7}>
                            <OrdersTable status="CANCELLED_COMPLETE" channel={channel} />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={8}>
                            <OrdersTable status="FAILED" channel={channel} />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={9}>
                            <OrdersTable status="PENDING" channel={channel} />
                        </CustomTabPanel>
                    </Box>
                </div>
            </div>
        </div>
    )
}
