import { Box, Button, IconButton, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { saveChannelSettings } from '../../../api/ChannelSetting';
import axios from 'axios';
import { BASE_URL, apiErrorManagemnt2 } from '../../../api/Custom';
import { getToken } from '../../../custome/action';
import Swal from 'sweetalert2';
import flipkart_logo from '../../../files/imgs/flipkart_logo2.png'
import { useGetAllWarehousesQuery } from '../../../hooks/RTK_Service/warehouse';
import DeleteIcon from '@mui/icons-material/Delete';
import { pink } from '@mui/material/colors';
import { v4 as uuidv4 } from 'uuid';


const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
};



export default function FlipkartSetting({ infoData, flipcart, handleFlipcart }) {
    const [channelSetting, setChannelSettings] = useState(null);
    const [inputFields, setInputFields] = useState([{ id: 1, value: '' }]);
    const [flktLocationField, setFlkrLocationField] = useState([{ id: 1, value: '' }]);

    const warehouseData = useGetAllWarehousesQuery({
        page: 1,
        limit: 1000,
    })
    if (warehouseData?.isError) {
        apiErrorManagemnt2(warehouseData?.error);
    }


    const handleAddFields = () => {
        const newId = inputFields.length + 1;
        setInputFields([...inputFields, { id: newId, value: '' }]);
        setFlkrLocationField([...flktLocationField, { id: newId, value: '' }]);
    };

    const handleInputChange = (id, event) => {
        const updatedFields = inputFields.map((field) =>
            field.id === id ? { ...field, value: event.target.value } : field
        );
        setInputFields(updatedFields);
    };

    const handleFlktInputChange = (id, event) => {
        const updatedFields = flktLocationField.map((field) =>
            field.id === id ? { ...field, value: event.target.value } : field
        );
        setFlkrLocationField(updatedFields);
    };

    const getFlktValue = (id) => {
        const updatedFieldsFlkt = flktLocationField.filter((field) => field.id === id);
        return updatedFieldsFlkt[0]?.value;
    }

    const handleRemoveFields = (id) => {
        const updatedFields = inputFields.filter((field) => field.id !== id);
        setInputFields(updatedFields);
        const updatedFieldsFlkt = flktLocationField.filter((field) => field.id !== id);
        setFlkrLocationField(updatedFieldsFlkt);
    };



    const handleSubmit = async (event) => {
        var flag = true;
        flktLocationField.map(dt => {
            if (dt.value === '' || dt.value == '' || dt.value == null) {
                alert("flipkart location is required...")       
                flag = false;
            }
        });
        inputFields.map(dt => {
            if (dt.value === '' || dt.value == '' || dt.value == null) {
                alert("Select the warehouse...")
                flag = false;
            }
        });
        if (flag) {
            event.preventDefault();
            const data = new FormData(event.currentTarget);
            const dataResult = {
                applicationId: data.get('ApplicationID'),
                applicationSecret: data.get('ApplicationSecret'),
                flipkartLoginId: data.get('FLIPKARTLOGINID'),
                flipcartPassword: data.get('FLIPKARTPASSWORD'),
                userId: infoData.userId,
                flipkartLocation: flktLocationField,
                warehouseIds: inputFields,
                channelId: infoData.channelId,
                channel: infoData.channel
            };

            await saveChannelSettings(dataResult)
            handleFlipcart()
        }
    }


    // const handleChange = (event) => {
    //     const {
    //         target: { value },
    //     } = event;
    //     setWarehouseIds(
    //         typeof value === 'number' ? value.split(',') : value,
    //     );
    // };


    const getWarehousList = () => {
        var options = [];
        warehouseData?.data?.data?.content?.map((wd) => {
            options.push({
                value: wd.warehouseId,
                label: wd.warehouseName,
            });
        })
        return options;
    }



    const getChannelSetting = async () => {
        await axios.post(`${BASE_URL}/manageecom/api/getChannelSetting`, infoData, {
            headers: {
                'Authorization': 'Bearer ' + getToken(),
                'Content-Type': 'application/json'
            }
        }).then(
            (response) => {
                setChannelSettings(response?.data);

                var arrOfWarehouse = [];
                var arrOfLocations = [];
                var id = 1;
                response?.data?.channelWarehouseMappingList?.map(md => {
                    var wObj = { id: id, value: md?.warehouseId };
                    var lObj = { id: id, value: md?.flipkartLocation };
                    id++;
                    arrOfWarehouse.push(wObj);
                    arrOfLocations.push(lObj);
                    //arr.push(md?.warehouseId);
                })
                setInputFields(arrOfWarehouse);
                setFlkrLocationField(arrOfLocations);
                //setWarehouseIds(arr);
                return response.data;
            },
            (error) => {
                console.log(error)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops, Something went wrong!',
                    text: error.message,
                })
            }
        ).catch(err => {
            return (err)
        });
    }

    const authorizeManageecom = () => {
        const uuid = uuidv4();
        const state = `${uuid}~${infoData.channelId}`;
        sessionStorage.setItem("flipkart_state", state);
        window.open('https://sandbox-api.flipkart.net/oauth-service/oauth/authorize?client_id=<client-id>&redirect_uri=<redirect-uri>&response_type=code&scope=Seller_Api&state=' + state,'_self')
    };

    useEffect(() => {
        const init = async () => {
            setInputFields([]);
            setFlkrLocationField([]);
            setChannelSettings(null);
            if (flipcart) {
                getChannelSetting()
            }
        }
        init();
    }, [flipcart])

    return (
        <div>

            <Modal
                open={flipcart}
                onClose={handleFlipcart}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">

                        <img src={flipkart_logo} width={40} height={40} /> <b>FLIPKART SETTINGS</b>
                    </Typography>
                    <Box component="form" onSubmit={(e)=>{return handleSubmit(e)}}>
                        <div className='row'>
                            <div className="col-12 col-md-6">
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="ApplicationID"
                                    label="Application ID"
                                    defaultValue={channelSetting?.applicationId}
                                    multiline
                                    name="ApplicationID"
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="ApplicationSecret"
                                    label="Application Secret"
                                    defaultValue={channelSetting?.applicationSecret}
                                    multiline
                                    name="ApplicationSecret"
                                />
                            </div>
                        </div>


                        {/* <div className='row'>
                            <div className="col-12 col-md-6">
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="FLIPKARTLOGINID"
                                    multiline
                                    label="Flipkart login id"
                                    defaultValue={channelSetting?.flipkartLoginId}
                                    id="FLIPKARTLOGINID"
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="FLIPKARTPASSWORD"
                                    multiline
                                    label="Flipkart password "
                                    defaultValue={channelSetting?.flipkartPassword}
                                    id="FLIPKARTPASSWORD"
                                />
                            </div>
                        </div> */}


                        <div>
                            {inputFields.map((inputField) => (
                                <div key={inputField.id}>

                                    <div className="row">
                                        <div className="col-md-6 mt-2">
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                name="FLIPKARTLOCATION"
                                                multiline
                                                label="Flipkart location"
                                                defaultValue={getFlktValue(inputField.id)}
                                                onChange={(event) => handleFlktInputChange(inputField.id, event)}
                                                id="FLIPKARTLOCATION"
                                            />
                                        </div>
                                        <div className="col-md-5">
                                            <InputLabel id="selectWarehouselabel">select warehouse</InputLabel>

                                            <Select
                                                style={{ width: "100%" }}
                                                labelId="selectWarehouselabel"
                                                id="demo-select-small"
                                                value={inputField.value}
                                                label="select warehouse"
                                                required={true}
                                                onChange={(event) => handleInputChange(inputField.id, event)}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {getWarehousList()?.map((data) => (
                                                    <MenuItem value={data.value}>{data?.label}</MenuItem>
                                                ))}

                                            </Select>

                                            {/* <Select
                                                style={{ width: "100%" }}
                                                labelId="select warehouse id"
                                                multiple
                                                value={warehouseIds}
                                                //onChange={(event=>{handleWarehouseInputChange(index,event)})}
                                                //  onChange={handleChange}
                                                //value={inputField.value}
                                                onChange={(event) => handleInputChange(inputField.id, event)}
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={MenuProps}
                                            >
                                                {getWarehousList()?.map((data) => (
                                                    <MenuItem key={data?.label} value={data?.value}>
                                                        <Checkbox checked={warehouseIds?.indexOf(data?.value) > -1} />
                                                        <ListItemText primary={data.value + " : " + data?.label} />
                                                    </MenuItem>
                                                ))}
                                            </Select> */}
                                        </div>
                                        <div className="col-md-1 mt-4">
                                            <IconButton size="large" color="danger" onClick={() => handleRemoveFields(inputField.id)} aria-label="delete">
                                                <DeleteIcon sx={{ color: pink[500] }} />
                                            </IconButton>


                                        </div>
                                    </div>
                                </div>
                            ))}

                            <Button variant="outlined" fullWidth type="button" onClick={handleAddFields}>
                                Add Warehouse Location
                            </Button>
                        </div>


                        <Button
                            onClick={()=>{authorizeManageecom()}}
                            fullWidth
                            variant="contained"
                            sx={{ mt: 2, mb: 2, py: 2, }}
                        >
                            Authorize ManageEcom
                        </Button>

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 2, mb: 2, py: 2, }}
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}
