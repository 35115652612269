import axios from "axios";
import { getToken } from "../../custome/action";
import { BASE_URL, SELLERS_BASE_URL } from "../../api/Custom";
import logo from '../../files/imgs/manageEcom-logo.png'
import Swal from "sweetalert2";


export const loadScript = (src) => {
    return new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = src;
        script.crossOrigin = 'anonymous'
        script.onload = () => {
            resolve(true);
        }
        script.onerror = () => {
            resolve(false);
        }
        document.body.appendChild(script);
    })
}

export const savePaymentDetails = async (data) => {
    try{
        await axios.post(`${BASE_URL}/manageecom/api/recharge_init`, data, {
            headers: {
                Authorization: "Bearer " + getToken(),
            }
        }).then(
            async (response) => {
                if(response?.data?.status===1){
                    Swal.fire("Invalid",response?.data?.message,"error");
                    return;
                }
                //razorpay payment integration
                const result = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
                if (!result) {
                    alert("network issue...");
                   // setLoader({type:null,loading:false});
                    return;
                }
                // console.log("amt " + JSON.stringify(response.data));
                const Razorpay_payment = JSON.parse(response?.data?.RZ_order);
                const order_ref_id = response?.data?.orderRefId;
                const user_id = response?.data?.userId;
                const name= response?.data?.userName;
                const contact = response?.data?.userContact;
                const email = response?.data?.userEmail

                //console.log("order id : "+Razorpay_payment.id);
                const options = {
                    key: response?.data?.rzKey,
                    currency: "INR",
                    name: "Manageecom payment gateway",
                    description: "better for growth",
                    image: logo,
                    order_id: Razorpay_payment.id,
                    handler: function (response) {

                        const payment_id = response.razorpay_payment_id;
                        const rz_order_id = response.razorpay_order_id;
                        const signature = response.razorpay_signature;

                        if (response.razorpay_payment_id) {
                            //update the states of order payment
                            const paymentObject = {
                                "rzPaymentId": payment_id,
                                "rzOrderId": rz_order_id,
                                "rzSignature": signature,
                                "orderID": Razorpay_payment.id,
                                "orderRefId" : order_ref_id,
                                "userId" : user_id
                            }
                            axios.post(`${SELLERS_BASE_URL}/update_recharge`, paymentObject, {
                                headers: {
                                    Authorization: "Bearer "+getToken(),
                                }
                            }).then(
                                (response) => {
                                    Swal.fire("Payment Success","Your unit and amount is added to account","success");
                                    //setLoader({type:null,loading:false});
                                    return "done";
                                },
                                (error) => {
                                    console.log(JSON.stringify(error));
                                    Swal.fire("Payment Faild","payment is fail","error");
                                    return null;
                                    //setLoader({type:null,loading:false});
                                }
                            )
            
                        }
                    },
                    prefill: {
                        name: name,
                        email:email,
                        contact:contact,                        
                    },
                    theme: {
                        color: "#1976d2"
                    }
                };

                const paymentObject = new window.Razorpay(options);
                paymentObject.open();

                //setLoader({type:null,loading:false});
                return "done";

            },
            (error) => {
               // setLoader({type:null,loading:false});
                alert("Something went wrong!!");
                console.error(error);
                return null;
            }
        )
    }catch(error){
        console.log(error);
    }
}
