import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';

export default function BtnLodderIcon({ action }) {
    return (
        <>
            {action &&
                <>
                    <CircularProgress
                        variant="indeterminate"
                        disableShrink
                        size={20}
                        thickness={5}
                        color="inherit"
                        sx={{ mx: 1 }}

                    />
                </>
            }
        </>
    )
}
