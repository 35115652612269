import React, { useState } from 'react'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
//import { RegisterUser } from '../../api/LoginRegister';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import { useSaveUserMutation } from '../../hooks/RTK_Service/userLoginRegister';
import { apiErrorManagemnt2 } from '../../api/Custom';
import { Helmet } from 'react-helmet';

export default function Register() {
    const [errorMessage, setErrorMessage] = useState(null);
    const [condition,setCondition]=useState(false);
    const navigate = useNavigate();
    const [saveUser,{isLoading : isLoading,isError : isError,error:error}] = useSaveUserMutation();

    const handleCondition=(value)=>{
        setCondition(value);
    }

    if(isError){
        apiErrorManagemnt2(error)
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const password = data.get('password');
        const rpassword = data.get('rpassword');
        

        // regular expressions to validate password
        var lowerCase = /[a-z]/g;
        var upperCase = /[A-Z]/g;
        var numbers = /[0-9]/g;

        if (password.length < 8) {
            setErrorMessage("Password length should be more than 8.");
        } else if (!password.match(lowerCase)) {
            setErrorMessage("Password should contains lowercase letters!");
        } else if (!password.match(upperCase)) {
            setErrorMessage("Password should contain uppercase letters!");
        } else if (!password.match(numbers)) {
            setErrorMessage("Password should contains numbers also!");
        } else if (password !== rpassword) {
            setErrorMessage(null);
            alert("password and confirm password not match!!!");
        }else if(condition===false){
            alert("please checked the checkbox.")
        } else {
            const registerData = {
                email: data.get('email'),
                password: data.get('password'),
                phone: data.get('phone'),
                firstName: data.get('firstName'),
                lastName: data.get('lastName'),
                companyName: data.get('cname'),
            };
            console.log(registerData.companyName)
          /*  await RegisterUser(registerData).then((r) => {
                navigate("/login?msg=rsuccess")
            }).catch((error) => {
                alert("registration error : " + error)
            })*/
            saveUser(registerData).then((res) => {
                if (res?.data?.status === 0) {
                   // Swal.fire("Data Save!!", res?.data?.message, "success");
                    navigate("/login?action=registrationSuccess&key=NU1293gh&msg=rsuccess")
                }
            })


        }
    };

    return (
        <div className='mt-nav'>
             <Helmet>
                <title>Register - Manageecom Solutions</title>
                <meta
                    name="description"
                    //content="provides multi-channel inventory Management software for E-commerce partners and Sellers in India. It helps you to track orders, control stock, and manage your business."
                    content="Manageecom new user registration page."
                />
                {/* multiple link elements */}
                <link rel="canonical" href={process.env.REACT_APP_MANAGEECOM_BASE_URL + "/register"} />
            </Helmet>
             <Header />
           
            <div className='back-div back-div-height-1000' />
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 12,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <div className='card back-div-card card-shadow'>

                        <Avatar sx={{ m: 1, bgcolor: 'var(--mainColor)' }}>
                            <VpnKeyIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign up
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        autoComplete="given-name"
                                        name="firstName"
                                        required
                                        fullWidth
                                        id="firstName"
                                        label="First Name"
                                        autoFocus
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="lastName"
                                        label="Last Name"
                                        name="lastName"
                                        autoComplete="family-name"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="cname"
                                        label="company name"
                                        name="cname"
                                        autoComplete="company name"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="phone"
                                        label="Enter Phone number"
                                        name="phone"
                                        autoComplete="phone"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="new-password"
                                    />
                                    <small className='text-danger'>{errorMessage}</small>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="rpassword"
                                        label="Confirm password"
                                        type="password"
                                        id="rpassword"
                                        autoComplete="new-password"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox onChange={(event)=>{handleCondition(event.target.checked)}}  color="primary" />}
                                        label="I want to receive inspiration, marketing promotions and updates via email."
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, py: 2 }}
                            >
                                {
                                    isLoading ?
                                        <span>Loading...</span>
                                    :
                                        <span>Sign Up</span>
                                }
                            </Button>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    Already have an account?
                                    <a onClick={()=>{navigate("/login")}} className='text-primary pointer' variant="body2">
                                         &nbsp;Sign in
                                    </a>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                </Box>
            </Container>
            <Footer />
        </div>

    );
}
