// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.service-img{
    width:220px;
    height:250px;
}
.h-fixed{
    height: 500px;
    overflow: hidden;
}
.service-card{
    position: relative;
}
.service-information-pannel{
    width: 100%;
    position: absolute;
    height:505px;
    top: 500px;
    background-color: #145587ec;
    transition: 1s all ease-in-out;
}
.service-card:hover .service-information-pannel{
    top: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/components/service/service.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;AACA;IACI,aAAa;IACb,gBAAgB;AACpB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,UAAU;IACV,2BAA2B;IAC3B,8BAA8B;AAClC;AACA;IACI,QAAQ;AACZ","sourcesContent":[".service-img{\n    width:220px;\n    height:250px;\n}\n.h-fixed{\n    height: 500px;\n    overflow: hidden;\n}\n.service-card{\n    position: relative;\n}\n.service-information-pannel{\n    width: 100%;\n    position: absolute;\n    height:505px;\n    top: 500px;\n    background-color: #145587ec;\n    transition: 1s all ease-in-out;\n}\n.service-card:hover .service-information-pannel{\n    top: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
