import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { SELLERS_BASE_URL } from '../../api/Custom'
import { getToken } from '../../custome/action'

export const channelApi = createApi({
    reducerPath: 'channelApi',
    baseQuery: fetchBaseQuery({ baseUrl: SELLERS_BASE_URL }),
    tagTypes : ['channel'],
    endpoints: (builder) => ({
        getAllChannels: builder.query({
            query: (state) => ({
                url: `/getChannels?pageNumber=${state?.page - 1}&limit=${state?.limit}`,
                method: 'GET',
                headers:  {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            providesTags : ['channel']
        }),
        getChannels: builder.mutation({
            query: (state) => ({
                url: `/getChannels?pageNumber=${state?.page - 1}&limit=${state?.limit}`,
                method: 'GET',
                headers:  {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            providesTags : ['channel']
        }),
        saveChannelDetails : builder.mutation({
            query : (data)=> ({
                url: `/createChannel`,
                method: 'POST',
                body:data,
                headers:  {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            invalidatesTags:['channel']
        }),
        updateChannelDetails : builder.mutation({
            query : (data) =>({
                url: `/updateChannel`,
                method: 'POST',
                body:data,
                headers:  {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            invalidatesTags:['channel']
        }),
        
       
    })
})

export const { useGetAllChannelsQuery , useGetChannelsMutation, useSaveChannelDetailsMutation, useUpdateChannelDetailsMutation} = channelApi