import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import './loader.css'
export default function Loader({ action }) {
    return (
        <>
            {action &&
                <div className='loader-div'>
                    <div className='loadder'>
                            <CircularProgress
                                variant="indeterminate"
                                disableShrink
                                size={50}
                                thickness={4}
                                color="primary"
                                sx={{ mx: 1 }}
                            />
                    </div>
                </div>
            }
        </>
    )
}
