import { Autocomplete, Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { ADD_PRODUCT_PATH, DASHBOARD_BASE_PATH, MANAGE_STOCK_PATH, UPDATE_TYPE_PRODUCT } from '../../../../custome/constants';
import { useUpdateRequestData } from '../../../../hooks/useUpdateRequestData';
import Tooltip from '@mui/material/Tooltip';
import CachedIcon from '@mui/icons-material/Cached';
import { useDispatch } from 'react-redux';
import { removeWarehouseData } from '../../../../store/warehouseSlice';
import { useNavigate } from 'react-router-dom';
import { removeUpdateDetails } from '../../../../store/updateData';

export default function StepOne({ setStepOneData, stepOneData, handleBack, handleNext, options, warehouseName }) {

    const [value, setValue] = React.useState(options[0]);
    const [inputValue, setInputValue] = React.useState('');
    const [categoryError, setCategoryError] = useState(null);
    const [skuError, setSkuError] = useState(null);
    const updateData = useUpdateRequestData(UPDATE_TYPE_PRODUCT);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleClickChangeWarehouse = () => {
        dispatch(removeWarehouseData());
        dispatch(removeUpdateDetails());
       // navigate("/dashboard/manage_stock?tpath=add_product2")
        navigate(DASHBOARD_BASE_PATH+MANAGE_STOCK_PATH+"?tpath="+ADD_PRODUCT_PATH);
    }

    const handleSubmitStepOneDetails = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const category = data.get("pcat");
        //  const sku = data.get("psku")
        const sku = inputValue;

        if (category === null || category?.length <= 0) {
            setCategoryError("product category is required !!");
        } else if (sku === null || sku?.length <= 0) {
            setCategoryError(null);
            setSkuError("product sku is required !!")
        } else if (category != null && sku != null) {
            setCategoryError(null)
            setSkuError(null);
            const obj = {
                category,
                sku
            }

            setStepOneData(obj);
            handleNext();
        }
    }

    return (
        <div>
            <Box className='' component="form" noValidate onSubmit={handleSubmitStepOneDetails} sx={{ mt: 3 }}>
                <Box className="center">
                    <Grid container xs={12} sm={6} md={7} >
                        <Grid item xs={12} my={1}>
                            <FormControl
                                error={categoryError != null ? true : false}
                                sx={{ minWidth: "100%" }}>
                                <InputLabel id="demo-simple-Select-Category">Select Category</InputLabel>
                                <Select
                                    labelId="demo-simple-Select-Category"
                                    id="demo-simple-select-category"
                                    label="Select Category"
                                    name='pcat'
                                    defaultValue={updateData != null ? updateData.categoryId : stepOneData?.category}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={'1'}>Video Gamed</MenuItem>
                                    <MenuItem value={'2'}>Digital Music</MenuItem>
                                    <MenuItem value={'3'}>Health and household items</MenuItem>
                                    <MenuItem value={'4'}>Patio, lawn, and garden</MenuItem>
                                    <MenuItem value={'5'}>Sports, outdoor, and fitness supplies</MenuItem>
                                    <MenuItem value={'6'}>Books</MenuItem>
                                    <MenuItem value={'7'}>Clothing, shoes, and jewelry</MenuItem>
                                    <MenuItem value={'8'}>Home and kitchen items</MenuItem>
                                    <MenuItem value={'9'}>Pet supplies</MenuItem>
                                    <MenuItem value={'10'}>Beauty and personal care</MenuItem>
                                    <MenuItem value={'11'}>Cell phone accessories</MenuItem>
                                    <MenuItem value={'12'}>Toys and games</MenuItem>
                                    <MenuItem value={'13'}>Tools and home improvement items</MenuItem>
                                    <MenuItem value={'14'}>Kitchen and dining items</MenuItem>
                                    <MenuItem value={'15'}>Grocery and gourmet food</MenuItem>
                                    <MenuItem value={'16'}>Office products</MenuItem>
                                    <MenuItem value={'17'}>Others</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} my={1} >
                            <FormControl
                                error={skuError != null ? true : false}
                                sx={{ minWidth: "100%" }}>
                                {/*
                                <Select
                                    labelId="demo-simple-select-sku"
                                    id="demo-simple-select-helper-sku"
                                    name='psku'
                                    label="Select SKU"
                                    defaultValue={updateData != null ? updateData.sku : stepOneData?.sku}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={'sku1'}>sku1</MenuItem>

                                </Select>*/}
                                <div className='mb-1' style={{ display: "flex", justifyContent: "space-between", textAlign: "center" }}>
                                    <small className='text-muted'>Warehouse : <b>{warehouseName}</b></small>&nbsp;&nbsp;
                                    {
                                        updateData==null &&
                                        <small>
                                            <span className='text-muted'><b>change warehouse?</b></span>&nbsp;&nbsp;
                                            <Tooltip onClick={() => { handleClickChangeWarehouse() }} className='text-primary pointer mr-3' title="Change warehouse">
                                                <CachedIcon />
                                            </Tooltip>
                                        </small>
                                    }
                                </div>
                                <Autocomplete
                                    // value={value}
                                    defaultValue={updateData?.sku}
                                    onChange={(event, newValue) => {
                                        setValue(newValue);
                                    }}
                                    inputValue={inputValue}
                                    onInputChange={(event, newInputValue) => {
                                        setInputValue(newInputValue);
                                    }}
                                    id="controllable-states-demo"
                                    options={options}
                                    sx={{ width: '100%' }}
                                    renderInput={(params) => <TextField {...params} label="Select SKU" />}
                                />
                                {
                                    skuError &&
                                    <small className='text-danger'>Please select sku !!</small>
                                }

                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button variant="outlined" type='submit'>
                        Next
                    </Button>
                </Box>
            </Box>

        </div>
    )
}
