import React, { useState } from 'react'
import { useGetJobTypeDataQuery } from '../../../hooks/RTK_Service/jobBatchApi'
import { Button, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { apiErrorManagemnt2 } from '../../../api/Custom';
import Information from '../../../components/custom/Information';
import { useNavigate } from 'react-router-dom';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { dawnloadProductErrorExcelFile } from '../../../api/JobManagement';


export default function JobManagement() {
    const [pageNumber, setPageNumber] = useState(1);
    const navigate=useNavigate();

    const jobTypeData = useGetJobTypeDataQuery({ page: pageNumber })

    if (jobTypeData?.isSuccess) {
        console.log(jobTypeData);
    }
    if (jobTypeData?.isError) {
        apiErrorManagemnt2(jobTypeData?.error)
    }

    return (
        <div className='container-fluid'>
            <div className='mt-2 spaceBetween'>
                <div className="text-center text-muted alignCenter" >
                    <h3>JOB MANAGEMENT RECORD</h3>
                    <Information info={process.env.REACT_APP_JOB_MANAGEMENT_INFORMATION_MSG} />
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className='left my-2'>
                    <Button className='importProductDataBtn' onClick={() => { navigate("/dashboard/job_batch_management") }} variant="contained" ><UploadFileIcon />&nbsp; Import product data</Button>
                </div>
            </div>
            <TableContainer mt={5} component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell align="left">Job Name</TableCell>
                            <TableCell align="left">Date</TableCell>
                            <TableCell align="left">Status</TableCell>
                            <TableCell align="left">Message</TableCell>
                            <TableCell align="left">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            jobTypeData?.currentData?.data?.content?.map((row) => (
                                
                                <TableRow
                                    key={row?.jobTypeId}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row?.jobTypeId}
                                    </TableCell>
                                    <TableCell align="left">{row?.jobName}</TableCell>
                                    <TableCell align="left">{row?.createdDtTime}</TableCell>
                                    <TableCell align="left">{row?.status}</TableCell>
                                    <TableCell align="left">{row?.message}</TableCell>
                                    {
                                        row?.status==='failed'?
                                        <TableCell align="left">
                                            <Button variant='contained' size='small' onClick={()=>{dawnloadProductErrorExcelFile(row?.jobTypeId, row?.template_url)}} >Download</Button>
                                        </TableCell>
                                        :
                                        <TableCell align="left"></TableCell>
                                    }
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="right mt-2">
                <Stack spacing={2}>
                    {
                        <Pagination color="primary" count={jobTypeData?.data?.data?.totalPages} page={pageNumber} onChange={(e, v) => setPageNumber(v)} />
                    }
                </Stack>
            </div>
        </div>
    )
}
