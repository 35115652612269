// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo{
    width:100px;
    height:50px;
}
.nav-item{
    padding:3px 5px;
    font-weight: 500;
}
.nav-link{
    cursor:pointer;
}

@media (min-width: 1500px) {
    .nav-item{
        font-size:20px;
    }
    .logo{
        width:122px;
        height:62px;
    }
  }


.siteTabs{
  position: fixed;
  right: 0;
  top:45%;
  z-index: 9999;
}
.site-tab{
  position: relative;
  right:-175px;
  width:220px;
  margin-top: 5px;
  height:50px;
  border-radius: 10px 0 0 10px;
  background-color:var(--mainColor2);
  transition: 2s all;
  display: flex;
  align-items: center;
}
.site-tab:hover{
  right:0px;
}
.number{
  color:white;
  margin-top: 13px;
  font-size: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/header/header.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;AACf;AACA;IACI,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,cAAc;AAClB;;AAEA;IACI;QACI,cAAc;IAClB;IACA;QACI,WAAW;QACX,WAAW;IACf;EACF;;;AAGF;EACE,eAAe;EACf,QAAQ;EACR,OAAO;EACP,aAAa;AACf;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,eAAe;EACf,WAAW;EACX,4BAA4B;EAC5B,kCAAkC;EAClC,kBAAkB;EAClB,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,SAAS;AACX;AACA;EACE,WAAW;EACX,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".logo{\n    width:100px;\n    height:50px;\n}\n.nav-item{\n    padding:3px 5px;\n    font-weight: 500;\n}\n.nav-link{\n    cursor:pointer;\n}\n\n@media (min-width: 1500px) {\n    .nav-item{\n        font-size:20px;\n    }\n    .logo{\n        width:122px;\n        height:62px;\n    }\n  }\n\n\n.siteTabs{\n  position: fixed;\n  right: 0;\n  top:45%;\n  z-index: 9999;\n}\n.site-tab{\n  position: relative;\n  right:-175px;\n  width:220px;\n  margin-top: 5px;\n  height:50px;\n  border-radius: 10px 0 0 10px;\n  background-color:var(--mainColor2);\n  transition: 2s all;\n  display: flex;\n  align-items: center;\n}\n.site-tab:hover{\n  right:0px;\n}\n.number{\n  color:white;\n  margin-top: 13px;\n  font-size: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
