import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { SELLERS_BASE_URL } from "../../api/Custom";
import { getToken } from "../../custome/action";

export const comboDataApi = createApi({
    reducerPath : 'comboDataApi',
    baseQuery : fetchBaseQuery({baseUrl:SELLERS_BASE_URL}),
    tagTypes : ['combodata'],
    endpoints : (builder) =>({
        getAllComboData : builder.query({
            query : (state)=>({
                url: `/getComboData?pageNumber=${state?.pageNumber-1}&limit=10&searchBy=${state?.searchBy}&searchValue=${state?.searchValue}`,
                method: 'GET',
                headers:  {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            providesTags:['combodata']
        }),
        checkSkuPresentOrNotInStock : builder.mutation({
            query : (sku)=>({
                url: `/checkSkuPresentOrNotInStock?sku=${sku}`,
                method: 'GET',
                headers:  {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
        }),
        saveComboData : builder.mutation({
            query :(data)=>({
                url: `/saveComboData`,
                method: 'POST',
                body:data,
                headers:  {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            invalidatesTags:['combodata']
        }),
        updateJSONComboData : builder.mutation({
            query :(data)=>({
                url: `/updateJsonComboData`,
                method: 'POST',
                body:data,
                headers:  {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            invalidatesTags:['combodata']
        }),

        removeComboData : builder.mutation({
            query :(comboDataId)=>({
                url: `/removeComboData?comboDataId=${comboDataId}`,
                method: 'GET',
                headers:  {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            invalidatesTags:['combodata']
        }),
    })
})

export const {useGetAllComboDataQuery , useRemoveComboDataMutation ,useCheckSkuPresentOrNotInStockMutation, useSaveComboDataMutation, useUpdateJSONComboDataMutation} = comboDataApi