export const steps = [
    {
        target: 'body',
        content : `👋 Welcome to manageecom`,
        placement: 'center',
        
    },
    {
        target: '.order-tab',
        content: 'This is report section',
        placement:'bottom'
    },
]