import React, { useState } from 'react'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import "./login.css"
import { useDispatch } from 'react-redux';
//import { loginUser } from '../../api/LoginRegister';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import { useLoginUserMutation } from '../../hooks/RTK_Service/userLoginRegister';
import { apiErrorManagemnt } from '../../api/Custom';
import { saveDetails } from '../../custome/action';
import { Helmet } from 'react-helmet';
import Swal from 'sweetalert2';
import { Toast } from '../../custome/popupMessage';
import Captcha from 'react-captcha-generator';
import BtnLodderIcon from '../../components/custom/BtnLodderIcon';



export default function Login() {
    const [userInput, setUserInput] = useState('');
    const [isVerified, setIsVerified] = useState(false);
    const [captcha, setCaptcha] = useState('');
    const dispatch = useDispatch();

    const result = (newCaptcha) => {
        setCaptcha(newCaptcha);
        setUserInput("");
        setIsVerified(false);
    };

    const navigate = useNavigate();
    const [emailError, setEmailError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);
    const [loginUser, { isLoading: isLoading, isError: isError, error: error }] = useLoginUserMutation();
    const queryParameters = new URLSearchParams(window.location.search)
    const message = queryParameters.get("msg")
    if (message === 'rsuccess') {
        //Swal.fire("Registration successful","please login with your credentials...","success");
        Toast.fire({
            icon: 'success',
            title: 'Registration successfull'
        })
    }

    if (isError) {
        loginUser().reset();
        apiErrorManagemnt(error);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();



        const data = new FormData(event.currentTarget);
        const loginData = {
            email: data?.get('email'),
            password: data?.get('password'),
        };
        //  const response = await LoginUser(loginData);
        if (loginData.email === '') {
            setEmailError("please fill valid user id .")
            return;
        }
        if (loginData.password === '') {
            setPasswordError("please fill password .");
            return
        }
        /*
        dispatch(loginUser(loginData)).then((result) => {
            if (result.payload) {
                navigate("/dashboard")
            }
        });*/

        if (captcha != '' && userInput != '' && captcha === userInput) {
            setIsVerified(true);
            loginUser(loginData).then((res) => {
                if (res?.data?.status === 0) {
                    saveDetails(res?.data?.token, res?.data?.name)
                    navigate("/dashboard");
                }
            })
            setUserInput("");
        } else {
            Swal.fire("Invalid Captcha!!", "captcha is not match, please fill correct captcha code.", "error");
        }




    };

    const handleInputChange = (event) => {
        setUserInput(event.target.value);
    };

    return (
        <div>
            <Helmet>
                <title>Login - Manageecom Solutions</title>
                <meta
                    name="description"
                    //content="provides multi-channel inventory Management software for E-commerce partners and Sellers in India. It helps you to track orders, control stock, and manage your business."
                    content="Manageecom login page."
                />
                {/* multiple link elements */}
                <link rel="canonical" href={process.env.REACT_APP_MANAGEECOM_BASE_URL + "/login"} />
            </Helmet>

            <Header />
            <div className='back-div back-div-height-800' />
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 11,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <div className='card back-div-card card-shadow'>
                        <Avatar sx={{ m: 1, bgcolor: 'var(--mainColor)' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography sx={{ color: 'var(--mainColor2)' }} component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                type='email'
                                autoComplete="email"
                                autoFocus
                            />
                            <small className='text-danger'>{emailError && emailError}</small>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <small className='text-danger'>{passwordError && passwordError}</small>

                            <div className='d-flex'>
                            <Captcha result={result} />
                            <TextField
                                style={{margin:"20px 0 0 5px"}}
                                required
                                fullWidth
                                type="text"
                                placeholder='enter captcha code'
                                value={userInput}
                                onChange={handleInputChange} />
                            </div>
                            

                            <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="Remember me"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, py: 2, }}
                            >
                                Sign In <BtnLodderIcon action={isLoading}/>
                            </Button>







                            <Grid container>
                                <Grid item xs>
                                    <Link href="#" variant="body2">
                                        Forgot password?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    Don't have an account?
                                    <a onClick={() => { navigate("/register") }} className='text-primary pointer' variant="body2">
                                        &nbsp;Sign Up
                                    </a>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                </Box>
            </Container>
            <Footer />
        </div>
    );

}
