import React, { useState } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LanguageIcon from '@mui/icons-material/Language';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import './contact.css'
import { useSaveContactUsMutation } from '../../hooks/RTK_Service/userLoginRegister';
import Swal from 'sweetalert2';

export default function Contact() {
    const [contactUsError, setContactUsError] = useState({ isError: false, type: null, message: "" });
    const [saveConatcatUs, { isLoading: isLoading, isError: isError, error: error,isSuccess:success }] = useSaveContactUsMutation();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (data.get("name") === null || data.get("name").length === 0) {
            setContactUsError({ isError: true, type: 'name', message: "full name is required !!" })
        } else if (data.get("email") === null || data.get("email").length === 0) {
            setContactUsError({ isError: true, type: 'email', message: "email is required !!" })
        } else if (data.get("subject") === null || data.get("subject").length === 0) {
            setContactUsError({ isError: true, type: 'subject', message: "subject is required !!" })
        } else if (data.get("message") === null || data.get("message").length === 0) {
            setContactUsError({ isError: true, type: 'message', message: "message is required !!" })
        } else {
            var dtObj = {
                "name": data.get("name"),
                "email": data.get("email"),
                "subject": data.get("subject"),
                "message": data.get("message")
            }
            saveConatcatUs(dtObj).then((res) => {
                Swal.fire({
                    icon: 'success',
                    toast: true,
                    position: 'bottom-right',
                    text: res?.data?.message,
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true
                })
                
            })

        }

    }
    return (
        <div className='contact-container container'>
            <div>
                <div style={{ boxSizing: 'border-box', overflow: 'hidden', borderColor: '--var(mainColor2)' }} className="card w-100">
                    <div className="row">
                        <div className="col-md-4 col-lg-5 text-light" style={{ backgroundColor: 'var(--mainColor2)' }}>
                            <div className='py-5 px-4'>
                                <h3>Let's get in touch</h3>
                                <p className=''>We're open for any suggestion or just to have chat</p>

                                <div className='left pt-3 d-flex v-center'>
                                    <Avatar sx={{ border: '1px solid white', bgcolor: 'var(--mainColor2)' }}>
                                        <LocationOnIcon />
                                    </Avatar>
                                    <div className='mx-3 my-2'>Nuvio Technologies Private Limited, Shop No 2, Shakti Dham, Plot No 108, Navi Mumbai</div>
                                </div>

                                <div className='left pt-3 d-flex v-center'>
                                    <Avatar sx={{ border: '1px solid white', bgcolor: 'var(--mainColor2)' }}>
                                        <LocalPhoneIcon />
                                    </Avatar>
                                    <div className='mx-3 my-2'>+91 7718895734</div>
                                </div>

                                <div className='left pt-3  d-flex v-center'>
                                    <Avatar sx={{ border: '1px solid white', bgcolor: 'var(--mainColor2)' }}>
                                        <MailOutlineIcon />
                                    </Avatar>
                                    <div className='mx-3 my-2'><a className='text-light' style={{ textDecoration: 'none' }} href='mailto:support@nuvio.in'>support@nuvio.in</a></div>
                                </div>

                                <div className='left pt-3  d-flex v-center'>
                                    <Avatar sx={{ border: '1px solid white', bgcolor: 'var(--mainColor2)' }}>
                                        <LanguageIcon />
                                    </Avatar>
                                    <div className='mx-3 my-2'><a className='text-light' href="https://manageecom.com"><u>https://manageecom.com</u></a></div>
                                </div>

                            </div>
                        </div>
                        <div className="col-12 col-md-8 col-lg-7 p-5">
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'left',
                                }}
                            >

                                <Typography component="h1" variant="h5">
                                    Get in touch
                                </Typography>
                                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                error={contactUsError.type === 'name' && contactUsError.isError}
                                                helperText={contactUsError.type === 'name' && contactUsError.message}
                                                autoComplete="given-name"
                                                name="name"
                                                required
                                                fullWidth
                                                id="name"
                                                label="Full name "
                                                variant="standard"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                error={contactUsError.type === 'email' && contactUsError.isError}
                                                helperText={contactUsError.type === 'email' && contactUsError.message}
                                                required
                                                fullWidth
                                                id="email"
                                                label="Email "
                                                name="email"
                                                autoComplete="family-name"
                                                type='email'
                                                variant="standard"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                error={contactUsError.type === 'subject' && contactUsError.isError}
                                                helperText={contactUsError.type === 'subject' && contactUsError.message}
                                                required
                                                fullWidth
                                                id="subject"
                                                label="Subject "
                                                name="subject"
                                                autoComplete="company name"
                                                variant="standard"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                error={contactUsError.type === 'message' && contactUsError.isError}
                                                helperText={contactUsError.type === 'message' && contactUsError.message}
                                                required
                                                fullWidth
                                                id="message"
                                                label="Enter message... "
                                                multiline
                                                name="message"
                                                rows={5}
                                                autoComplete="company name"
                                                variant="standard"
                                            />
                                        </Grid>

                                    </Grid>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ mt: 5, px: 5, backgroundColor: 'var(--mainColor2)' }}
                                    >
                                        {
                                            isLoading ?
                                                <>Sending...</>
                                                :
                                                <>Send</>
                                        }
                                    </Button>
                                    <br/>
                                    {
                                        success &&
                                        <small className='text-success'>your message send successfully to admin.</small>
                                    }

                                </Box>
                            </Box>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
