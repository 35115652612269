// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-circle{
    width:65px;
    height: 45px;
    border: 1px solid white;
    border-radius: 50%;
}
@media (min-width: 1300px) {
    .contact-circle{
        width:57px;
        height: 49px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/contact/contact.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,uBAAuB;IACvB,kBAAkB;AACtB;AACA;IACI;QACI,UAAU;QACV,YAAY;IAChB;EACF","sourcesContent":[".contact-circle{\n    width:65px;\n    height: 45px;\n    border: 1px solid white;\n    border-radius: 50%;\n}\n@media (min-width: 1300px) {\n    .contact-circle{\n        width:57px;\n        height: 49px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
