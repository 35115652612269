import React from 'react'
import { useGetSettlementQuery } from '../../../hooks/RTK_Service/settlementApi';
import { useState } from 'react';
import { Pagination, Stack } from '@mui/material';
import { apiErrorManagemnt2 } from '../../../api/Custom';

export default function Settlement() {
    const [page, setPage] = useState(1);
    const settlementDetails = useGetSettlementQuery(page);
    if (settlementDetails.isSuccess) {
       // console.log(settlementDetails?.data);
    }

    if(settlementDetails.isError){
        apiErrorManagemnt2(settlementDetails?.error)
    }


    return (
        <div className='p-1'>
            <div className='mt-2 spaceBetween'>
                <div className="text-center text-muted alignCenter" >
                    <h3>SETTLEMENT DETAILS</h3>
                </div>
            </div>
            <table className="table table-hover mt-2">
                <thead style={{ borderBottom: "1px solid black" }}>
                    <tr>
                        <th scope="col">ID</th>
                        <th scope="col">M. EMAIL</th>
                        <th scope="col">PRODUCT ID</th>
                        <th scope="col">QTY</th>
                        <th scope="col">ITEM AMT</th>
                        <th scope="col">M. CHARGE</th>
                        <th scope="col">S. CHARGE</th>
                        <th scope="col">FINAL AMT</th>
                        <th scope="col">ORDER STATUS</th>
                        <th scope="col">STATUS</th>
                    </tr>
                </thead>
                <tbody className='SettlementTableDetails'>

                    {
                        settlementDetails?.data?.settlementData?.content?.map(sData => {
                           return <tr className=''>
                                <td>S{sData?.settlementId}</td>
                                <td>{sData?.marketplaceEmail}</td>
                                <td>{sData?.orderProduct?.productId}</td>
                                <td>{sData?.itemQuantity}</td>
                                <td>₹{sData?.itemPrice}</td>
                                <td>₹{sData?.marketplaceCharge}</td>
                                <td>₹{sData?.serviceCharge}</td>
                                <td>₹{sData?.finalAmt}</td>
                                <td>{sData?.orderProduct?.status}</td>
                                <td>{sData?.status}</td>
                            </tr>
                        })
                    }


                </tbody>
            </table>

            <div className="right mt-2">
                <Stack spacing={2}>
                    {
                        <Pagination color="primary" count={settlementDetails?.data?.data?.totalPages} page={page} onChange={(e, v) => setPage(v)} />
                    }
                </Stack>
            </div>
        </div>
    )
}
