import React, { useEffect } from 'react'
import Contact from '../../components/contact/Contact'
import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import { Container } from '@mui/material'
import { Helmet } from 'react-helmet'

export default function ContactPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Contact Us - Manageecom Solutions</title>
        <meta name="description" content="For any inquiries about the software and the company, feel free to contact us on 7718895734 or email us at support@nuvio.in" />

        {/* multiple link elements */}
        <link rel="canonical" href={process.env.REACT_APP_MANAGEECOM_BASE_URL+"/contact"} />

      </Helmet>
      <Header />
      <div className='back-small-div' />

      <Container className="back-small-div-container mb-4">
        <div className='center'>
          <div >
            <h1 className="text-light text-center"><b>CONTACT US</b></h1>
            <h6 className='text-light text-center'>We're open for any suggestion or just to have chat.</h6>
          </div>
        </div>
      </Container>
      <Contact className="mt-20" />
      <Footer />
    </div>
  )
}
