import React, { useState } from 'react'
import { Avatar, Box, Button, Card, CardActions, CardContent, CardMedia, Checkbox, Divider, FormControl, FormControlLabel, InputLabel, ListItemText, MenuItem, Select, TextField, Typography } from '@mui/material'
import { getCitysList, getStatesList } from '../../../api/warehouse';
import { useSaveShippingPartnerDataMutation } from '../../../hooks/RTK_Service/shippingPartnerApi';
import { apiErrorManagemnt2 } from '../../../api/Custom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { DASHBOARD_BASE_PATH, SHIPPING_PARTNER_DASHBOARD } from '../../../custome/constants';


export default function LinkIthinkLogistic({ options,setOpenTable, setShippingPartner  }) {
    const [checkBox, setCheckbox] = useState(false);
    const [stateId, setStateId] = useState(0);
    const [cityId, setCityId] = useState(0);
    const [countryId, setCountryId] = useState(0);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [update, setUpdate] = useState(true);



    const [saveShippingPartner, saveSpFunctions] = useSaveShippingPartnerDataMutation();
    if (saveSpFunctions.isError) {
        apiErrorManagemnt2(saveSpFunctions.error);
    }

    const handleCountryChange = (event) => {
        getStatesList(event.target.value).then(res => {
            setStateList(res?.data?.data);
            setCountryId(event.target.value);
            setUpdate(!update);
        })
    }

    const handleStateChange = (event) => {
        setStateId(event.target.value);
        getCitysList(event.target.value).then(res => {
            setCityList(res?.data?.data);
        })
    }

    const handleCityChange = (event) => {
        setCityId(event.target.value);
    }

    const handleSubmitIthinkForm = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        var obj = {
            checkBox,
            token: data.get("iltoken"),
            key: data.get("ilkey"),
            warehouseId: data.get("warehouseId"),
            city: data.get("city"),
            pincode: data.get("pincode"),
            country: data.get("country"),
            state: data.get("state"),
            type: "ithinkLogistic"
        }
        saveShippingPartner(obj).then(res => {
            setOpenTable("ithinkLogisticTable");
            setShippingPartner(null);
            Swal.fire("Success","Warehouse link to shipping partner successfully","success");
        })
        return true;
    }

    return (
        <div className='container-fluid'>
            <Avatar className="pointer" onClick={() => { setOpenTable(null); setShippingPartner(null) }} sx={{ width: 31, height: 31, bgcolor: "paper", mr: 1 }}>
                    <ArrowBackIcon />
                </Avatar>
            <div className='row my-4'>
                
                <div className='col-12 col-md-6 offset-md-3'>

                    <Card sx={{ width: "100%" }}>
                        <CardContent>
                            <Typography className='text-center' sx={{ fontSize: 16 }} variant='h1' gutterBottom>
                                Link Warehouse To Shipping Partner
                            </Typography>
                            <Divider>*</Divider>

                            <FormControlLabel onClick={() => { setCheckbox(!checkBox) }} control={<Checkbox />} /><>use our ithink logistic account.</>

                            <Box component="form" onSubmit={(e) => { return handleSubmitIthinkForm(e) }}>
                                {!checkBox &&
                                    <>
                                        <TextField
                                            margin="normal"
                                            size="small"
                                            required
                                            fullWidth
                                            id="ithinkSp"
                                            label="Ithink logistic shipping partner token"
                                            multiline
                                            name="iltoken"
                                        />
                                        <TextField
                                            margin="normal"
                                            size="small"
                                            required
                                            fullWidth
                                            id="ithinkSp"
                                            label="Ithink logistic shipping partner key"
                                            multiline
                                            name="ilkey"
                                        />
                                    </>
                                }

                                <Typography sx={{ fontSize: 11 }} variant='h6'>
                                    Select Warehouse
                                </Typography>

                                <Select
                                    style={{ width: "100%" }}
                                    labelId="selectWarehouselabel"
                                    id="demo-select-small"
                                    size="small"
                                    label="select warehouse"
                                    required={true}
                                    name="warehouseId"
                                >
                                    <MenuItem value="">
                                        <em>Select Warehouse</em>
                                    </MenuItem>
                                    {options?.map((data) => (
                                        <MenuItem value={data.value}>{data?.label}</MenuItem>
                                    ))}

                                </Select>

                                {/* <Select
                      style={{ width: "100%" }}
                      labelId="select warehouse id"
                      multiple
                      size='small'
                      value={warehouseIds}
                      onChange={handleChange}
                      renderValue={(selected) => selected?.join(', ')}
                      MenuProps={MenuProps}
                    >
                      {getWarehousList()?.map((data) => (
                        <MenuItem key={data?.label} value={data?.value}>
                          <Checkbox checked={warehouseIds?.indexOf(data?.value) > -1} />
                          <ListItemText primary={data.value + " : " + data?.label} />
                        </MenuItem>
                      ))}
                    </Select> */}


                                <div className='row'>
                                    <div className="col-6">
                                        <TextField
                                            margin="normal"
                                            size="small"
                                            fullWidth
                                            id="warehousePincode"
                                            label="Pincode"
                                            name="pincode"
                                            required={true}
                                            multiline
                                        />
                                    </div>


                                    <div className="col-6">
                                        <FormControl sx={{ mt: 2, width: "100%" }}>
                                            <InputLabel id="demo-simple-select-helper-label">Country</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                size="small"
                                                label="Country"
                                                name="country"
                                                onChange={(event) => { handleCountryChange(event) }}
                                            >
                                                <MenuItem value="101">
                                                    <em>India</em>
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-6">
                                        <FormControl sx={{ width: "100%" }}>
                                            <InputLabel id="demo-simple-select-helper-label">State</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                size="small"
                                                label="State"
                                                name="state"
                                                onChange={(event) => { handleStateChange(event) }}
                                            >
                                                <MenuItem value="">
                                                    <em>select state</em>
                                                </MenuItem>
                                                {
                                                    stateList?.map(stateData => {
                                                        return <MenuItem value={stateData?.id}>{stateData?.state_name}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-6">
                                        <FormControl sx={{ width: "100%" }}>
                                            <InputLabel id="demo-simple-select-helper-label">Select City</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                size="small"
                                                label="Select City"
                                                name="city"
                                                onChange={(event) => { handleCityChange(event) }}
                                            >
                                                <MenuItem value="">
                                                    <em>select city</em>
                                                </MenuItem>
                                                {
                                                    cityList?.map(cityData => {
                                                        return <MenuItem value={cityData?.id}>{cityData?.city_name}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <CardActions>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 2, py: 1, }}
                                    >
                                        Submit
                                    </Button>
                                </CardActions>
                            </Box>
                        </CardContent>

                    </Card>
                </div>
            </div>
        </div >
    )
}
