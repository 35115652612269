import { Box, Button, Paper, Stack, TableContainer, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useState } from 'react'
import { apiErrorManagemnt2 } from '../../../api/Custom';
import { useGetAllRegisteredMarketplaceQuery, useRequestToMarketplaceMutation } from '../../../hooks/RTK_Service/marketplaceApi';
import Swal from 'sweetalert2';
import { Toast } from '../../../custome/popupMessage';
import BtnLodderIcon from '../../../components/custom/BtnLodderIcon';
import Information from '../../../components/custom/Information';
import HelpTooltip from '../../../components/custom/HelpTooltip';
//registerd active marketplace
export default function RegisteredMarketplaces({ marketplace }) {
    const { data, isError, error, isSuccess } = useGetAllRegisteredMarketplaceQuery();
    const [requestToMarketplace, { isError: reqIsError, error: reqError, isSuccess: reqSuccess, isLoading: reqLoading }] = useRequestToMarketplaceMutation();
    if (isError) {
        apiErrorManagemnt2(error);
    }
    if (reqIsError) {
        apiErrorManagemnt2(reqError)
    }

    var activatedMarketplace = [];
    marketplace?.data?.marketplace?.content?.map((row) => {
        activatedMarketplace.push(row?.companyEmail);
    })
    var filteredData = [];
    if (isSuccess) {
        try {
            filteredData = JSON.parse(data?.marketplaces)?.filter((row) => activatedMarketplace.includes(row?.emailAddr) === false)
        } catch (error) {
            Toast.fire("Data not present !!", "", "warning")
        }
    }


    const hadleToRequestMarketplace = (data) => {
        if(reqLoading){
            return;
        }
        if(data?.apiEndPoint===null || data?.apiEndPoint===""){
            Swal.fire("Marketplace site error..", "This marketplace is not set up yet, try it later", "warning");
            return;
        }
        var obj = {
            marketplaceName: data?.nameOfCompany,
            marketplaceId: data?.marketPlaceID,
            marketplaceUrl: data?.apiEndPoint,
            marketplaceEmail: data?.emailAddr
        }
        requestToMarketplace(obj).then(res => {
            if (res?.data?.status === 0) {
                Swal.fire("Success", "The request has been successfully sent to the marketplace, wait a while for the marketplace to accept the request.", "success");
            }
        })
    }

    const columns = [
        { field: 'marketPlaceID', headerName: 'ID', width: 70, height: 300 },
        {
            field: 'Marketplace Info',
            headerName: 'Marketplace Info',
            description: 'All marketplace full information',
            sortable: false,
            width: 370,

            renderCell: (params) => {
                return (
                    <Stack className="marketplaceInfo" >
                        <span><b>Company Name :</b>{params.row.nameOfCompany}</span>
                        <span><b>Company Email :</b>{params.row.emailAddr}</span>
                        <span><b>Company Contact :</b>{params.row.phoneNumber}</span>
                        <span><b>Type :</b>{params.row.typeOfCompany}</span>
                        <span><b>Api Endpoint :</b>{
                            (params?.row?.apiEndPoint===null || params?.row?.apiEndPoint==="")?
                            <span className='text-danger'> api url not valid !!</span>
                            :
                            <>{params?.row?.apiEndPoint}</>
                            }</span>
                    </Stack>
                );
            },
        },
        {
            field: 'Action',
            headerName: 'Actions',
            description: 'Request to marketplace owner to add for selling product.',
            sortable: false,
            width: 180,
            border: "none",
            renderCell: (params) => {
                const onClick = (e) => {
                    hadleToRequestMarketplace(params.row)
                };

                return (
                    <Stack>
                        <div className='right sendRequestBtn'>
                            <Button variant="contained" size="small" onClick={onClick}>Request To Add</Button>
                        </div>
                    </Stack>
                );
            },
        },

    ];


    return (
        <TableContainer component={Paper} sx={{ height: "90vh", overflow: "auto", overflowX: "hidden" }}>
                
                <div className='spaceBetween'>
                    <Typography sx={{ mt: 1, ml: 1 }} component="h1" variant="h6">
                        Request Marketplace <BtnLodderIcon action={reqLoading} /> 
                    </Typography>
                    <HelpTooltip className="mx-1" msg={process.env.REACT_APP_REQUESTMARKETPLACE_TOOLTIP_MSG} />
                </div>
                <>
                    <Information info={process.env.REACT_APP_REQUESTMARKETPLACE_INFORMATION_MSG} />
                </>
                <DataGrid
                    rowHeight={120}
                    getRowId={(row) => row?.marketPlaceID}
                    rows={filteredData}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 15 },
                        },
                    }}
                    pageSizeOptions={[15, 20, 50, 100, 150]}
                />
           </TableContainer>
    )
}
