import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getComboDataByIdDetails } from '../../../api/ComboData';
import Swal from 'sweetalert2';
import { hideLoading, showLoading } from '../../../store/loader';
import { Button } from '@mui/material';

export default function RemoveComboData() {
    const [removeComboData, setRemoveComboData] = useState(null);
    const [showRemoveDataBtn, setShowRemoveDataBtn] = useState(false);
    const [isChange, setIsChange] = useState(false);
    const dispatch = useDispatch();

    const getUpdateData = async (id) => {
        dispatch(showLoading());
        getComboDataByIdDetails(id).then((res) => {
            dispatch(hideLoading());
            if (res.status === 0) {
                setRemoveComboData(res.comboData);
            } else if (res.status === 1) {
                Swal.fire("Error,Bad Input!!", res.message, 'error');
            }
        }, (err) => {
            dispatch(hideLoading())

        })
    }

    const removeDataFromComboData = (key) => {
        const data_obj = JSON.parse(removeComboData?.jsonData);
        data_obj.splice(key, 1);
        removeComboData.jsonData = JSON.stringify(data_obj);
        console.info(removeComboData);
        setShowRemoveDataBtn(true)
        setIsChange(!isChange);
    }

    const saveRemoveComboData = () => {
        setShowRemoveDataBtn(false)

        Swal.fire({
            icon: 'success',
            toast: true,
            position: 'bottom-right',
            text: "combo data successfully removed.",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true
        })
    }

    useEffect(() => {
        getUpdateData(1);
    }, [])
    return (

        <>
            <div className="text-center text-muted mt-2">
                <h2><b>REMOVE COMBO DATA</b></h2>
                {
                    showRemoveDataBtn && <><Button
                        color="error"
                        onClick={saveRemoveComboData}
                        style={{ width: "200px" }}
                        variant="contained"
                        sx={{ mt: 3, py: 2 }}
                    >
                        Save Remove Data
                    </Button><br />
                        <small className='text-danger'><b>Note : </b>please click on save remove data button when make any deletion.</small>
                    </>
                }
            </div>


            <div className="center mt-5">
            <div className="table-responsive w-50">
                <table className="table table-hover mt-2">
                    <thead style={{ borderBottom: "1px solid black" }}>
                        <tr>
                            <th scope="col">ID </th>
                            <th scope="col">SKU</th>
                            <th scope="col">QUANTITY</th>
                            <th scope="col">ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        {removeComboData && JSON.parse(removeComboData?.jsonData)?.map((data, key) => {
                            return (
                                <tr key={"ComboRemoveID" + data.comboDataId}>
                                    <td style={{width:"15px"}} className='text-center'>{key + 1}</td>
                                    <td>{data?.sku}</td>
                                    <td className='text-center' style={{width:"10px"}}>{data?.quantity}</td>
                                    <td className='text-center' style={{width:"15px"}}><button onClick={() => { removeDataFromComboData(key) }} className='btn btn-sm btn-outline-danger'>Remove</button></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            </div>
        </ >

    )
}
