import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import logo from "../../files/imgs/manageEcom-logo.png"
import ListItems from './ListItems';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getName, getToken, logout, removeDetails, saveDetails, saveLoginFromOutside } from '../../custome/action';
import { useState } from 'react';
import { DASHBOARD_BASE_PATH, PROFILE } from '../../custome/constants';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useMediaQuery } from '@mui/material';

const drawerWidth = 245;

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" to="https://manageecom.com/">
                manageecom.com
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
    [theme.breakpoints.down('sm')]: {
        width: '0px',
    },
});


const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function MainDashboard() {
    const theme = useTheme();
    const matches = useMediaQuery('(min-width:500px)');//false=mobile true=laptop
    const [open, setOpen] = React.useState(matches);
    const [channelListOpen, setchannelListOpen] = useState(false);
    const [reportListOpen, setReportListOpen] = useState(false);
    const [masterListOpen, setMasterListOpen] = useState(false);
    const [wmsListOpen, setWmsListOpen] = useState(false);
    const [marketplaceListOpen, setMarketplaceListOpen] = useState(false);
    const [shippingPartnerListOpen, setShippingPartnerListOpen] = useState(false);

    const [firstTimeLogin, setFirstTimeLogin] = useState(true);
    const navigate = useNavigate();

   

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    

   

    
    //########################################################
    //nuvioseller.com login setup
    // Use useLocation to get the current location object, including query parameters
    const location = useLocation();

    // Access the query parameters from location.search
    const searchParams = new URLSearchParams(location.search);
    const urltoken = searchParams.get('token');
    const urlname = searchParams.get('name');
    if (urltoken != null && urlname != null) {
        saveDetails(urltoken, urlname);
        saveLoginFromOutside();
    }

    //######################################################



    const name = getName();
    const token = getToken();
    if (token === null || name === '' || name === null) {
        removeDetails();
        window.location.assign("/login")
    } else {
        // Check if session storage is available.
        if (typeof sessionStorage != "undefined") {
            // Check if there's already an entry.
            if (sessionStorage.getItem("visited") == null) {
                // It's not set.
                // Show some message.
                setFirstTimeLogin(true);
                // Set the visited to true.
                sessionStorage.setItem("visited", true);
            }
        }
    }
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const handleClickChannel = () => {
        setchannelListOpen(!channelListOpen);
        setReportListOpen(false);
        setMasterListOpen(false);
        setWmsListOpen(false);
        setMarketplaceListOpen(false);
        setShippingPartnerListOpen(false);
    };


    const handleClickReport = () => {
        setReportListOpen(!reportListOpen);
        setchannelListOpen(false);
        setMasterListOpen(false);
        setWmsListOpen(false);
        setMarketplaceListOpen(false);
        setShippingPartnerListOpen(false);
    };

    const handleClickMaster = () => {
        setMasterListOpen(!masterListOpen);
        setchannelListOpen(false);
        setReportListOpen(false);
        setWmsListOpen(false);
        setMarketplaceListOpen(false);
        setShippingPartnerListOpen(false);
    };

    const handleClickWms = () => {
        setMasterListOpen(false);
        setchannelListOpen(false);
        setReportListOpen(false);
        setWmsListOpen(!wmsListOpen);
        setMarketplaceListOpen(false);
        setShippingPartnerListOpen(false);
    };

    const handleClickMarketplace = () => {
        setMasterListOpen(false);
        setchannelListOpen(false);
        setReportListOpen(false);
        setWmsListOpen(false);
        setMarketplaceListOpen(!marketplaceListOpen);
        setShippingPartnerListOpen(false);
    }

    const handleClickShippingPartner = () => {
        setShippingPartnerListOpen(!shippingPartnerListOpen);
        setMasterListOpen(false);
        setchannelListOpen(false);
        setReportListOpen(false);
        setWmsListOpen(false);
        setMarketplaceListOpen(false);
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar
                    sx={{
                        pr: '10px', // keep right padding when drawer closed
                    }}
                >

                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginLeft: '-8px',
                                marginRight: "5px",
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>

                        <Link to="/dashboard">
                        <img src={logo} alt="ManageEcom" style={{ width: matches ? '100px' : '70px', height: matches ? '50px' : '38px' }} />
                        </Link> 


                    </Typography>

                    <IconButton className='mr-3 notification' color="inherit">
                        <Badge badgeContent={4} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                    <Typography variant="h6" sx={{ px: 3, textTransform: 'capitalize' }} noWrap component="div">
                        <a className="userName nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <span>{name}&nbsp;</span>
                        </a>
                        <ul className="dropdown-menu">
                            <li onClick={() => { navigate(DASHBOARD_BASE_PATH + PROFILE) }} className='pointer'><a className="dropdown-item"  >Profile</a></li>
                            <li><hr className="dropdown-divider" /></li>
                            <li onClick={() => { logout() }} className='pointer'><a className="dropdown-item">logout</a></li>
                        </ul>
                    </Typography>

                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>

                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <ListItems
                    handleClickChannel={() => { handleClickChannel() }} channelListOpen={channelListOpen}
                    handleClickReport={() => { handleClickReport() }} reportListOpen={reportListOpen}
                    handleClickMaster={() => { handleClickMaster() }} masterListOpen={masterListOpen}
                    handleClickWms={() => { handleClickWms() }} wmsListOpen={wmsListOpen}
                    handleClickMarketplace={() => { handleClickMarketplace() }} marketplaceListOpen={marketplaceListOpen}
                    handleClickShippingPartner={() => { handleClickShippingPartner() }} shippingPartnerListOpen={shippingPartnerListOpen}
                />
            </Drawer>
            <Box component="main" sx={{ mt: 1, mb: 3, pb: 1, width: `calc(100% - ${drawerWidth}px)`, height: '100vh', flexGrow: 1 }}>
                <Toolbar />
                <Outlet />
                <Copyright sx={{ pt: 4, m: 0 }} />
            </Box>

        </Box>
    );
}