import React from 'react';
import {
    Container,
    Typography,
    Box,
    Paper,
    Divider,
} from '@mui/material';
import { Helmet } from 'react-helmet';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';

const TermsAndConditions = () => {
    return (
        <div>
            <Helmet>
                <title>Terms And Conditions - Manageecom Solutions</title>
                <meta name="description" content="This software gives you all multichannel eCommerce business management features at the best price. " />

                {/* multiple link elements */}
                <link rel="canonical" href={process.env.REACT_APP_MANAGEECOM_BASE_URL + "/terms_and_conditions"} />

            </Helmet>
            <Header />
            <div className='back-small-div' />
            <Container className="back-small-div-container mb-4">
                <div className='center mt-5'>
                    <div >
                        <h1 className="text-light text-center"><b>Terms and Conditions</b></h1>
                        <h6 className='text-light text-center'>Effective Date: 06-09-2024</h6>
                    </div>
                </div>
            </Container>
            <Container maxWidth="md" style={{ marginTop: '80px', marginBottom: '10px' }}>
                <Paper elevation={3} style={{ padding: '20px' }}>
                    <Typography variant="body1" paragraph>
                        Welcome to ManageEcom.com, operated by Nuvio Technologies Private Limited. By using our website and services, you agree to comply with and be bound by the following terms and conditions. Please read these carefully before using our platform.
                    </Typography>

                    <Divider style={{ margin: '20px 0' }} />

                    <Typography variant="h6" gutterBottom>
                        1. Acceptance of Terms
                    </Typography>
                    <Typography variant="body1" paragraph>
                        By accessing or using ManageEcom.com, you agree to be bound by these Terms and Conditions. If you do not agree to these terms, you may not use the website or its services.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        2. Description of Service
                    </Typography>
                    <Typography variant="body1" paragraph>
                        ManageEcom.com provides users with a comprehensive platform to manage and optimize their e-commerce operations. This includes but is not limited to specific services or functionalities like order management, inventory tracking, etc..
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        3. Eligibility
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You must be at least 18 years of age to use ManageEcom.com. By using the service, you represent and warrant that you have the right, authority, and capacity to enter into this agreement and to abide by all the terms and conditions.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        4. Account Registration
                    </Typography>
                    <Typography variant="body1" paragraph>
                        To use our services, you must register for an account. You agree to provide accurate and complete information during the registration process and to update this information as necessary. You are responsible for maintaining the confidentiality of your account credentials and are fully responsible for all activities that occur under your account.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        5. Subscription and Fees
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Some features of ManageEcom.com may require a paid subscription. By subscribing, you agree to pay the fees associated with the chosen plan. Subscription fees are non-refundable unless otherwise stated in a specific promotion or offer. We reserve the right to change our pricing or subscription plans at any time.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        6. User Conduct
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You agree not to use the service for any unlawful purpose or in a manner that could damage, disable, or overburden the platform. Prohibited activities include, but are not limited to:
                    </Typography>
                    <ul>
                        <li>Attempting to gain unauthorized access to other accounts or systems.</li>
                        <li>Transmitting malware, viruses, or any other malicious code.</li>
                        <li>Using the platform to violate any applicable local, state, national, or international law.</li>
                    </ul>

                    <Typography variant="h6" gutterBottom>
                        7. Intellectual Property
                    </Typography>
                    <Typography variant="body1" paragraph>
                        All content and materials on ManageEcom.com, including but not limited to text, graphics, logos, and software, are the property of Nuvio Technologies Private Limited or its licensors and are protected by intellectual property laws. You may not reproduce, distribute, or create derivative works from any material without prior written consent from Nuvio Technologies Private Limited.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        8. Third-Party Services and Links
                    </Typography>
                    <Typography variant="body1" paragraph>
                        ManageEcom.com may contain links to third-party websites or services that are not owned or controlled by us. We are not responsible for the content, privacy policies, or practices of any third-party websites or services.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        9. Limitation of Liability
                    </Typography>
                    <Typography variant="body1" paragraph>
                        In no event shall Nuvio Technologies Private Limited be liable for any indirect, incidental, special, consequential, or punitive damages arising out of your use or inability to use the service, even if we have been advised of the possibility of such damages.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        10. Termination
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We reserve the right to terminate or suspend your account and access to ManageEcom.com at our sole discretion, without notice or liability, for any reason, including but not limited to your breach of these Terms and Conditions.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        11. Governing Law
                    </Typography>
                    <Typography variant="body1" paragraph>
                        These Terms and Conditions shall be governed by and construed in accordance the laws, without regard to its conflict of law principles.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        12. Amendments
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We reserve the right to modify or update these Terms and Conditions at any time. Your continued use of the service after any changes have been made constitutes acceptance of the revised terms.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        13. Contact Information
                    </Typography>
                    <Typography variant="body1" paragraph>
                        If you have any questions or concerns regarding these Terms and Conditions, please contact us at:
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Nuvio Technologies Private Limited
                        <br />
                        Nuvio Technologies Pvt Ltd. Shop No. 2, Shakti Dham CHS, Plot No. 125, Sector - 21, Ghansoli, Navi Mumbai - 400701, Maharashtra, India

                        <br />
                        Email: support@nuvio.in
                        <br />
                        Phone: +91 7718895734
                    </Typography>
                </Paper>
            </Container>
            <Footer />
        </div>
    );
};

export default TermsAndConditions;
