import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useState } from 'react'
import { useUpdateRequestData } from '../../../../hooks/useUpdateRequestData';
import { UPDATE_TYPE_PRODUCT } from '../../../../custome/constants';

export default function StepFour({ setStepFourData, stepFourData, handleBack, handleNext }) {
    const [productMainImage, setProductMainImage] = useState(null);
    const [productSubImage1, setProductSubImage1] = useState(null);
    const [productSubImage2, setProductSubImage2] = useState(null);
    const [productSubImage3, setProductSubImage3] = useState(null);
    const [productImageError, setProductImageError] = useState({ isError: false, type: null, message: '' });
    const updateData = useUpdateRequestData(UPDATE_TYPE_PRODUCT);

    const [img1, setImg1] = useState(process.env.REACT_APP_MANAGEECOM_BASE_URL + "/img/" + updateData?.productRefId + "/" + updateData?.mainImage);
    function handleChangeImg1(e) {
        if (e?.target?.files.length === 0) {
            setImg1(null);
        } else {
            setImg1(URL?.createObjectURL(e?.target?.files[0]));
        }
    }
    const [img2, setImg2] = useState(process.env.REACT_APP_MANAGEECOM_BASE_URL + "/img/" + updateData?.productRefId + "/" + updateData?.imageUrl1);
    function handleChangeImg2(e) {
        if (e?.target?.files.length === 0) {
            setImg2(null);
        } else {
            setImg2(URL.createObjectURL(e.target.files[0]));
        }
    }
    const [img3, setImg3] = useState(process.env.REACT_APP_MANAGEECOM_BASE_URL + "/img/" + updateData?.productRefId + "/" + updateData?.imageUrl2);
    function handleChangeImg3(e) {
        if (e?.target?.files.length === 0) {
            setImg3(null);
        } else {
            setImg3(URL.createObjectURL(e.target.files[0]));
        }
    }
    const [img4, setImg4] = useState(process.env.REACT_APP_MANAGEECOM_BASE_URL + "/img/" + updateData?.productRefId + "/" + updateData?.imageUrl3);
    function handleChangeImg4(e) {
        if (e?.target?.files.length === 0) {
            setImg4(null);
        } else {
            setImg4(URL.createObjectURL(e.target.files[0]));
        }
    }



    const handleSubmitStepFourDetails = () => {

        //console.log(productMainImage);
        if (updateData === null) {
            if (productMainImage === null) {
                setProductImageError({ isError: true, type: 'mainImg', message: "product main image is required" })
            } else if (productMainImage.type !== "image/jpeg") {
                setProductImageError({ isError: true, type: 'mainImg', message: "Image must be jpeg or jpg" })
            } else if (productSubImage1 === null) {
                setProductImageError({ isError: true, type: 'subImg1', message: "product image one is required" })
            } else if (productSubImage1.type !== "image/jpeg") {
                setProductImageError({ isError: true, type: 'subImg1', message: "Image must be jpeg or jpg" })
            } else if (productSubImage2 !== null && productSubImage2?.type !== "image/jpeg") {
                setProductImageError({ isError: true, type: 'subImg2', message: "Image must be jpeg or jpg" })
            } else if (productSubImage3 !== null && productSubImage3?.type !== "image/jpeg") {
                setProductImageError({ isError: true, type: 'subImg3', message: "Image must be jpeg or jpg" })
            } else {
                setProductImageError({ isError: false, type: null, message: '' })
                const imgObj = {
                    main: productMainImage,
                    sub1: productSubImage1,
                    sub2: productSubImage2,
                    sub3: productSubImage3
                }
                setStepFourData(imgObj);
                handleNext();
            }
        } else {
            if (productMainImage !== null && productMainImage?.type !== "image/jpeg") {
                setProductImageError({ isError: true, type: 'main', message: "Image must be jpeg or jpg" })
            } else if (productSubImage1 !== null && productSubImage1.type !== "image/jpeg") {
                setProductImageError({ isError: true, type: 'subImg1', message: "Image must be jpeg or jpg" })
            } else if (productSubImage2 !== null && productSubImage2.type !== "image/jpeg") {
                setProductImageError({ isError: true, type: 'subImg2', message: "Image must be jpeg or jpg" })
            } else if (productSubImage3 !== null && productSubImage3.type !== "image/jpeg") {
                setProductImageError({ isError: true, type: 'subImg3', message: "Image must be jpeg or jpg" })
            } else {
                setProductImageError({ isError: false, type: null, message: '' })
                const imgObj = {
                    main: productMainImage,
                    sub1: productSubImage1,
                    sub2: productSubImage2,
                    sub3: productSubImage3
                }
                setStepFourData(imgObj);
                handleNext();
            }
        }
    }

    return (
        <div>
            <Box className='' sx={{ mt: 3 }}>
                <Box className="center">
                    <Grid container xs={12} sm={6} md={7} >
                        <Grid item xs={12} mt={1}>
                            <Typography variant="caption" className='text-muted'>Main Image *</Typography><br />
                            <div className='text-center'>
                                <img src={img1} style={{ minHeight: "200px", maxHeight:"450px" }} className='img-fluid' />
                            </div>
                            <TextField
                                autoComplete="given-name"
                                name="productMainImage"
                                required={updateData != null ? false : true}
                                fullWidth
                                error={productImageError.type === 'mainImg' && productImageError.isError}
                                helperText={productImageError.type === 'mainImg' && productImageError.message}
                                id="productMainImage"
                                type='file'
                                onChange={event => {
                                    //console.log(event.target.files[0])
                                    handleChangeImg1(event);
                                    setProductMainImage(event.target.files[0])
                                }}
                                sx={{ mb: 2 }}

                            />
                        </Grid>

                        <Grid item xs={12} mt={1}>
                            <Typography variant="caption" className='text-muted'>Image One *</Typography><br />
                            <div className='text-center'>
                                <img src={img2} style={{ minHeight: "200px", maxHeight:"450px" }} className='img-fluid' />
                            </div>
                            <TextField
                                autoComplete="given-name"
                                name="productSubImageOne"
                                required={updateData != null ? false : true}
                                fullWidth
                                error={productImageError.type === 'subImg1' && productImageError.isError}
                                helperText={productImageError.type === 'subImg1' && productImageError.message}
                                id="productSubImageOne"
                                type='file'
                                onChange={event => {
                                    //console.log(event.target.files[0])
                                    handleChangeImg2(event);
                                    setProductSubImage1(event.target.files[0])
                                }}
                                sx={{ mb: 2 }}

                            />
                        </Grid>

                        <Grid item xs={12} mt={1}>
                            <Typography variant="caption" className='text-muted'>Image Two</Typography><br />
                            <div className='text-center'>
                            <img src={img3} style={{ minHeight: "200px", maxHeight:"450px" }} className='img-fluid' />
                            </div>
                            <TextField
                                autoComplete="given-name"
                                name="productSubImageTwo"
                                required
                                fullWidth
                                error={productImageError.type === 'subImg2' && productImageError.isError}
                                helperText={productImageError.type === 'subImg2' && productImageError.message}
                                id="productSubImageTwo"
                                type='file'
                                onChange={event => {
                                    //console.log(event.target.files[0])
                                    handleChangeImg3(event);
                                    setProductSubImage2(event.target.files[0])
                                }}
                                sx={{ mb: 2 }}

                            />
                        </Grid>

                        <Grid item xs={12} mt={1}>
                            <Typography variant="caption" className='text-muted'>Image Three</Typography><br />
                            <div className='text-center'>
                                <img src={img4} style={{ minHeight: "200px", maxHeight:"450px" }} className='img-fluid' />
                            </div>
                            <TextField
                                autoComplete="given-name"
                                name="productSubImageThree"
                                required
                                fullWidth
                                error={productImageError.type === 'subImg3' && productImageError.isError}
                                helperText={productImageError.type === 'subImg3' && productImageError.message}
                                id="productSubImageThree"
                                type='file'
                                onChange={event => {
                                    //console.log(event.target.files[0])
                                    handleChangeImg4(event);
                                    setProductSubImage3(event.target.files[0])
                                }}
                                sx={{ mb: 2 }}

                            />
                        </Grid>


                    </Grid>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                        color="inherit"
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button variant="outlined" onClick={handleSubmitStepFourDetails}>
                        Next
                    </Button>
                </Box>
            </Box>

        </div>
    )
}
