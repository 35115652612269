export const steps = [
    {
        target: 'body',
        title : `👋 Lets start the warehouse page quick information tour`,
        placement: 'center',
        
    },
    {
        target: '.addWarehouseBtn',
        title: 'To Add new warehouse details..',
        placement:'bottom'
    },
    {
        target: '.table-responsive',
        title: 'Added Warehouse details show here',
        placement:'bottom'
    },
    {
        target: '.warehouseId',
        title: 'This is warehouse id',
        placement:'bottom'
    },
    {
        target: '.warehouseUpdate',
        title: 'To update warehouse details',
        placement:'bottom'
    }
    
]