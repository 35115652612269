import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { SELLERS_BASE_URL } from '../../api/Custom'
import { getToken } from '../../custome/action'

export const productApi = createApi({
    reducerPath: 'productApi',
    baseQuery: fetchBaseQuery({ baseUrl: SELLERS_BASE_URL }),
    tagTypes: ['products','searchProducts'],
    endpoints: (builder) => ({
        getProducts: builder.query({
            query: (page) => ({
                url: `/getProductDetails?page=${page - 1}&limit=10`,
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            providesTags: ['products']
        }),
        getProductsByWarehouseId: builder.query({
            query: (data) => ({
                url: `/getProductDetailsByWarehouseId?page=${data?.page - 1}&limit=10&warehouseId=${data?.warehouseId}`,
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            providesTags: ['products']
        }),
        saveProduct: builder.mutation({
            query: (data) => ({
                url: `/saveProductDetails`,
                method: 'POST',
                body: data,
                headers: {
                    'Authorization': 'Bearer ' + getToken(),
                }
            }),
            invalidatesTags: ['products']
        }),

        updateProduct: builder.mutation({
            query: (data) => ({
                url: `/updateProductDetails`,
                method: 'POST',
                body: data,
                headers: {
                    'Authorization': 'Bearer ' + getToken(),
                }
            }),
            invalidatesTags: ['products','searchProducts']
        }),
        
        deleteProducts: builder.mutation({
            query: (productRefId) => ({
                url: `/deleteProductDetails?productRefId=${productRefId}`,
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            invalidatesTags: ['products','searchProducts']
        }),
        searchProduct : builder.mutation({
            query:(data)=>({
                url: `/searchProducts?searchBy=${data?.searchBy}&searchValue=${data?.searchValue}&warehouseId=${data?.warehouseId}`,
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            providesTags:["searchProducts"]
        })

    })
})

export const { useGetProductsQuery, useGetProductsByWarehouseIdQuery ,useDeleteProductsMutation,useSearchProductMutation,useSaveProductMutation,useUpdateProductMutation, useDawnloadExcelProductMutation } = productApi