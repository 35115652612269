import React from 'react'
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Collapse, Tooltip } from '@mui/material';
import { ExpandLess, ExpandMore, StarBorder } from '@mui/icons-material';
import List from '@mui/material/List';
import { Link } from 'react-router-dom';

import DashboardIcon from '@mui/icons-material/Dashboard';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ContactsIcon from '@mui/icons-material/Contacts';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { CHANNEL_ORDER_PATH, DASHBOARD_BASE_PATH, JOB_MANAGEMENT, MANAGE_VARIATION_PATH, STOCK_HISTORY_PATH } from '../../custome/constants';

export default function ListItems({ handleClickChannel, handleClickReport, handleClickMaster, handleClickWms, channelListOpen, reportListOpen, masterListOpen, wmsListOpen, handleClickMarketplace, marketplaceListOpen, handleClickShippingPartner, shippingPartnerListOpen }) {

    return (
        <>

            <List component="nav">
                <Link className='link' to="/dashboard">
                    <ListItemButton>
                        <Tooltip title="Dashboard" placement="right">
                            <ListItemIcon>
                                {/* <FontAwesome
                                    className='text-muted'
                                    name="house"
                                    size='2x'
                                /> */}
                                <DashboardIcon />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText className="text-muted" primary="Dashboard" />
                    </ListItemButton>
                </Link>

                {/* REPORTS PANNEL LIST */}
                <ListItemButton className='reportMainTab' id="report" onClick={handleClickReport}>
                    <Tooltip title="Reports" placement="right">
                        <ListItemIcon>
                            {/* <FontAwesome
                                name="folder-open"
                                className='fa-2x text-muted'
                            /> */}
                            <AssessmentIcon />
                        </ListItemIcon>
                    </Tooltip>
                    <ListItemText className='text-muted' primary="Reports" />
                    {reportListOpen ? <ExpandLess className="text-muted" /> : <ExpandMore className="text-muted" />}
                </ListItemButton>

                <Collapse in={reportListOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <Link className='link' to={DASHBOARD_BASE_PATH+STOCK_HISTORY_PATH}>
                            <ListItemButton sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <StarBorder className='text-muted' />
                                </ListItemIcon>
                                <ListItemText className="text-muted" primary="Stock History" />
                            </ListItemButton>
                        </Link>
                    </List>
                </Collapse>

                {/* CHANNEL PANNEL LIST  */}
                <ListItemButton className='channelMainTab' onClick={handleClickChannel}>
                    <Tooltip title="Channel" placement="right">
                        <ListItemIcon>
                            {/* <FontAwesome

                                name="folder-open"
                                className='fa-2x text-muted'
                            /> */}
                            <AddBusinessIcon />
                        </ListItemIcon>
                    </Tooltip>


                    <ListItemText className='text-muted' primary="Channel" />
                    {channelListOpen ? <ExpandLess className="text-muted" /> : <ExpandMore className="text-muted" />}
                </ListItemButton>
                <Collapse in={channelListOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <Link className='link' to="/dashboard/manage_channel">
                            <ListItemButton sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <StarBorder className='text-muted' />
                                </ListItemIcon>
                                <ListItemText className="text-muted" primary="Manage Channel" />
                            </ListItemButton>
                        </Link>
                        <Link className='link' to="/dashboard/mapped_unmmaped_products">
                            <ListItemButton sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <StarBorder className='text-muted' />
                                </ListItemIcon>
                                <ListItemText className='text-muted' primary="Channel Products" />
                            </ListItemButton>
                        </Link>
                        <Link className='link' to={DASHBOARD_BASE_PATH + CHANNEL_ORDER_PATH}>
                            <ListItemButton sx={{ pl: 4 }}>
                                <Tooltip title="Channel Orders" placement="right">
                                    <ListItemIcon>
                                        <StarBorder className='text-muted' />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText className="text-muted" primary="Channel Orders" />
                            </ListItemButton>
                        </Link>
                    </List>
                </Collapse>

                {/* MASTER PANNEL LIST */}
                <ListItemButton className='masterMainTab' onClick={handleClickMaster}>
                    <Tooltip title="Master" placement="right">
                        <ListItemIcon >
                            {/* <FontAwesome
                                name="calendar-minus"
                                size='2x'
                                className='text-muted'
                            /> */}
                            <ContactsIcon />
                        </ListItemIcon>
                    </Tooltip>
                    <ListItemText className="text-muted" primary="Master" />
                    {masterListOpen ? <ExpandLess className="text-muted" /> : <ExpandMore className="text-muted" />}
                </ListItemButton>
                <Collapse in={masterListOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <Link className='link manageStockSubTab' to="/dashboard/manage_stock">
                            <ListItemButton sx={{ pl: 4 }}>
                                <Tooltip title="Manage Stocks" placement="right">
                                    <ListItemIcon>
                                        <StarBorder className='text-muted' />
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText className="text-muted" primary="Manage Stock" />
                            </ListItemButton>
                        </Link>

                        <Link className='link' to="/dashboard/manage_products">
                            <ListItemButton sx={{ pl: 4 }}>
                                <Tooltip title="Manage Product" placement="right">
                                    <ListItemIcon>
                                        <StarBorder className='text-muted' />
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText className="text-muted" primary="Manage Product" />
                            </ListItemButton>
                        </Link>

                        <Link className='link manageStockSubTab' to={DASHBOARD_BASE_PATH + MANAGE_VARIATION_PATH}>
                            <ListItemButton sx={{ pl: 4 }}>
                                <Tooltip title="Manage Variations" placement="right">
                                    <ListItemIcon>
                                        <StarBorder className='text-muted' />
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText className="text-muted" primary="Manage Variations" />
                            </ListItemButton>
                        </Link>


                        <Link className='link' to="/dashboard/combo_data">
                            <ListItemButton sx={{ pl: 4 }}>
                                <Tooltip title="Combo Data" placement="right">
                                    <ListItemIcon>
                                        <StarBorder className='text-muted' />
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText className="text-muted" primary="Combo Data" />
                            </ListItemButton>
                        </Link>

                        <Link className='link' to={DASHBOARD_BASE_PATH + JOB_MANAGEMENT}>
                            <ListItemButton sx={{ pl: 4 }}>
                                <Tooltip title="Job Management" placement="right">
                                    <ListItemIcon>
                                        <StarBorder className='text-muted' />
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText className="text-muted" primary="Job Management" />
                            </ListItemButton>
                        </Link>

                    </List>
                </Collapse>

                {/* WMS PANNEL LIST */}
                <ListItemButton className='wmsMainTab' onClick={handleClickWms}>
                    <Tooltip title="Warehouse" placement="right">
                        <ListItemIcon>
                            {/* <FontAwesome
                                name="folder-open"
                                className='fa-2x text-muted'
                            /> */}
                            <EventAvailableIcon />
                        </ListItemIcon>
                    </Tooltip>
                    <ListItemText className='text-muted' primary="Warehouse" />
                    {wmsListOpen ? <ExpandLess className="text-muted" /> : <ExpandMore className="text-muted" />}
                </ListItemButton>

                <Collapse in={wmsListOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <Link className='link' to="/dashboard/manage_warehouse">
                            <ListItemButton sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <StarBorder className='text-muted' />
                                </ListItemIcon>
                                <ListItemText className='text-muted' primary="Manage Warehouse" />
                            </ListItemButton>
                        </Link>
                    </List>
                </Collapse>



                {/* Shipping partner PANNEL LIST */}
                <ListItemButton className='shippingPartnerMainTab' onClick={handleClickShippingPartner}>
                    <Tooltip title="Shipping Partner" placement="right">
                        <ListItemIcon>
                            {/* <FontAwesome
                                name="folder-open"
                                className='fa-2x text-muted'
                            /> */}
                            <EventAvailableIcon />
                        </ListItemIcon>
                    </Tooltip>
                    <ListItemText className='text-muted' primary="Shipping Partner" />
                    {shippingPartnerListOpen ? <ExpandLess className="text-muted" /> : <ExpandMore className="text-muted" />}
                </ListItemButton>

                <Collapse in={shippingPartnerListOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <Link className='link' to="/dashboard/shipping_partner">
                            <ListItemButton sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <StarBorder className='text-muted' />
                                </ListItemIcon>
                                <ListItemText className='text-muted' primary="Shipping Partner" />
                            </ListItemButton>
                        </Link>
                    </List>
                </Collapse>


                {/* MARKETPLACE PANNEL LIST */}
                <ListItemButton className="marketplaceMainTab" onClick={handleClickMarketplace}>
                    <Tooltip title="Marketplace" placement="right">
                        <ListItemIcon>
                            {/* <FontAwesome
                                name="folder-open"
                                className='fa-2x text-muted'
                            /> */}
                            <WarehouseIcon />
                        </ListItemIcon>
                    </Tooltip>
                    <ListItemText className='text-muted' primary="Marketplace" />
                    {marketplaceListOpen ? <ExpandLess className="text-muted" /> : <ExpandMore className="text-muted" />}
                </ListItemButton>

                <Collapse in={marketplaceListOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <Link className='link' to="/dashboard/manage_marketplace">
                            <ListItemButton sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <StarBorder className='text-muted' />
                                </ListItemIcon>
                                <ListItemText className='text-muted' primary="Marketplaces" />
                            </ListItemButton>
                        </Link>

                        <Link className='link' to="/dashboard/marketplace_orders">
                            <ListItemButton sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <StarBorder className='text-muted' />
                                </ListItemIcon>
                                <ListItemText className='text-muted' primary="Orders" />
                            </ListItemButton>
                        </Link>
                        
                        <Link className='link' to="/dashboard/settlement_details">
                            <ListItemButton sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <StarBorder className='text-muted' />
                                </ListItemIcon>
                                <ListItemText className='text-muted' primary="Settlement" />
                            </ListItemButton>
                        </Link>

                    </List>
                </Collapse>
            </List>
        </>
    )
}
