// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-section{
    margin-top: 200px;
    background-color: rgb(240 239 239 / 59%);
    padding-top: 50px;
    box-shadow: 0 4px 8px 0 rgb(221 217 217), 0 25px 50px 0 rgba(177, 176, 176, 0.19);
    border:none;
}
.footer-logo{
    width: 230px;
    height: 140px;
}
.footer-divider{
    width:340px;
    height: 3px;
    background-color: var(--mainColor);
    margin: 10px 0 0 0;
}
.sec1-info{
    margin: 10px 0 0 0;
}
.copyright-section{
    width:100%;
    display: flex;
    margin: 10px 0 0 0;
    padding: 5px;
    justify-content: center;
    align-items: center;
}
.divider{
    width: 100%;
    margin: 40px 0px 10px 0;
    height: 1px;
    background-color: rgba(128, 128, 128, 0.285);
}`, "",{"version":3,"sources":["webpack://./src/components/footer/footer.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,wCAAwC;IACxC,iBAAiB;IACjB,iFAAiF;IACjF,WAAW;AACf;AACA;IACI,YAAY;IACZ,aAAa;AACjB;AACA;IACI,WAAW;IACX,WAAW;IACX,kCAAkC;IAClC,kBAAkB;AACtB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,UAAU;IACV,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,uBAAuB;IACvB,WAAW;IACX,4CAA4C;AAChD","sourcesContent":[".footer-section{\n    margin-top: 200px;\n    background-color: rgb(240 239 239 / 59%);\n    padding-top: 50px;\n    box-shadow: 0 4px 8px 0 rgb(221 217 217), 0 25px 50px 0 rgba(177, 176, 176, 0.19);\n    border:none;\n}\n.footer-logo{\n    width: 230px;\n    height: 140px;\n}\n.footer-divider{\n    width:340px;\n    height: 3px;\n    background-color: var(--mainColor);\n    margin: 10px 0 0 0;\n}\n.sec1-info{\n    margin: 10px 0 0 0;\n}\n.copyright-section{\n    width:100%;\n    display: flex;\n    margin: 10px 0 0 0;\n    padding: 5px;\n    justify-content: center;\n    align-items: center;\n}\n.divider{\n    width: 100%;\n    margin: 40px 0px 10px 0;\n    height: 1px;\n    background-color: rgba(128, 128, 128, 0.285);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
