import React, { useEffect } from 'react'
import Header from '../../components/header/Header'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Container } from '@mui/material'
import Footer from '../../components/footer/Footer'
import about from '../../files/imgs/about.svg'
import { Helmet } from 'react-helmet';


export default function AboutPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Helmet>
                <title>About Us - Manageecom Solutions</title>
                <meta name="description" content="This software gives you all multichannel eCommerce business management features at the best price. " />

                {/* multiple link elements */}
                <link rel="canonical" href={process.env.REACT_APP_MANAGEECOM_BASE_URL + "/about"} />

            </Helmet>
            <Header />
            <div className='back-small-div' />

            <Container className="back-small-div-container mb-4">
                <div className='center mt-5'>
                    <div >
                        <h1 className="text-light text-center"><b>ABOUT US</b></h1>
                        <h6 className='text-light text-center'>Nice to meet you.</h6>
                    </div>
                </div>
            </Container>
            <Container>
                <div className='title-section2 container'>
                    <p className="text-muted text-justify">
                        ManageEcom, is India's leading service company offering complete solutions to E-commerce companies / Marketplace Seller globally. Our Service includes - "Catalogue Management", "Inventory Management", "Order Processing", "Reconciliation" and "POS Solution".</p>

                    <p className='text-muted text-justify'>We at ManageEcom, provides all cataloguing support services for all Marketplace or your own website (Like Magento and those website which accept the template based Listing). Post Unique Content Creation, making it very easy & hassle free for E-commerce partners and Seller/merchants. Our Team have deep expertise on managing content through various stages of its lifecycle and have successfully planned and executed projects all over India.</p>

                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <p>ManageEcom is committed & planning to invest significantly to strengthen the eco-system for Sellers, by automating the process to help merchants grow their business.</p>
                            <p>ManageEcom is a well known name in the eCommerce industry in India. The company was founded by a group of budding professionals. ManageEcom is an online eCommerce solutions organization that deliver real results to businesses globally with assured level of reliability and performance.</p>

                            <div className='left my-3'>
                                <a className='text-success' href="https://api.whatsapp.com/send/?phone=917718895734" ><WhatsAppIcon /></a>&nbsp;
                            </div>

                        </div>
                        <div className="col-12 col-sm-6">
                            <div className="text-right">
                                <img className='d-none d-md-block'  style={{ width: "486px", height: "328px", marginTop: '-54px' }} src={about} alt="about us" />
                            </div>
                        </div>
                    </div>
                </div>

            </Container>
            <Footer />
        </div>
    )
}
