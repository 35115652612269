// PrivacyPolicy.js
import React from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, Box, Card, CardContent } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Footer from '../../components/footer/Footer';
import { Helmet } from 'react-helmet';
import Header from '../../components/header/Header';

const PrivacyPolicy = () => {
    return (
        <div>
            <Helmet>
                <title>Privacy Policy - Manageecom Solutions</title>
                <meta name="description" content="This software gives you all multichannel eCommerce business management features at the best price. " />

                {/* multiple link elements */}
                <link rel="canonical" href={process.env.REACT_APP_MANAGEECOM_BASE_URL + "/privacy_policy"} />

            </Helmet>
            <Header />
            <div className='back-small-div' />
            <Container className="back-small-div-container mb-4">
                <div className='center mt-5'>
                    <div >
                        <h1 className="text-light text-center"><b>Privacy Policy</b></h1>
                        <h6 className='text-light text-center'>Effective Date: 06-09-2024</h6>
                    </div>
                </div>
            </Container>

            <Container maxWidth="md" sx={{ paddingTop: '63px', paddingBottom: '2rem' }}>
                <Box sx={{ textAlign: 'center', marginBottom: '2rem' }}>
                    <Typography variant="body1" color="textSecondary">
                        By using ManageEcom.com, you agree to the terms outlined in this Privacy Policy. If you do not agree, please do not use our services.
                    </Typography>
                </Box>

                {/* Section 1 - Information We Collect */}
                <Card sx={{ marginBottom: '1rem' }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            1. Information We Collect
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            a. Personal Information
                        </Typography>
                        <Typography variant="body2" paragraph>
                            When you register for an account or use our services, we may collect the following personal information:
                        </Typography>
                        <Typography variant="body2" component="ul">
                            <li>Name</li>
                            <li>Email address</li>
                            <li>Phone number</li>
                            <li>Billing and payment information</li>
                            <li>Any other information you provide to us during registration or through communication.</li>
                        </Typography>

                        <Typography variant="subtitle1" gutterBottom>
                            b. Non-Personal Information
                        </Typography>
                        <Typography variant="body2">
                            We also collect non-personal information about how you interact with our website. This includes:
                        </Typography>
                        <Typography variant="body2" component="ul">
                            <li>IP address</li>
                            <li>Browser type</li>
                            <li>Device type</li>
                            <li>Referring website</li>
                            <li>Pages visited on our site</li>
                            <li>Time spent on our site</li>
                            <li>Cookies and other tracking technologies (see section on Cookies and Tracking Technologies).</li>
                        </Typography>
                    </CardContent>
                </Card>

                {/* Section 2 - How We Use Your Information */}
                <Card sx={{ marginBottom: '1rem' }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            2. How We Use Your Information
                        </Typography>
                        <Typography variant="body2">
                            We use your information to provide, maintain, and improve our services. Specifically, we may use your data for the following purposes:
                        </Typography>
                        <Typography variant="body2" component="ul">
                            <li>To create and manage your account.</li>
                            <li>To process your transactions.</li>
                            <li>To send you administrative or promotional communications.</li>
                            <li>To personalize your experience on ManageEcom.com.</li>
                            <li>To improve the functionality of our website.</li>
                            <li>To comply with legal obligations and resolve any disputes.</li>
                        </Typography>
                    </CardContent>
                </Card>

                {/* Section 3 - Sharing of Information */}
                <Card sx={{ marginBottom: '1rem' }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            3. Sharing of Information
                        </Typography>
                        <Typography variant="body2">
                            We do not sell your personal information to third parties. However, we may share your information in the following instances:
                        </Typography>
                        <Typography variant="body2" component="ul">
                            <li>
                                <strong>Service Providers:</strong> We may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analytics, and customer support.
                            </li>
                            <li>
                                <strong>Business Transfers:</strong> If we are involved in a merger, acquisition, or asset sale, your personal information may be transferred to the new owners.
                            </li>
                            <li>
                                <strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid legal requests, such as a subpoena or court order.
                            </li>
                        </Typography>
                    </CardContent>
                </Card>

                {/* Section 4 - Cookies and Tracking */}
                <Card sx={{ marginBottom: '1rem' }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            4. Cookies and Tracking Technologies
                        </Typography>
                        <Typography variant="body2" paragraph>
                            ManageEcom.com uses cookies and other tracking technologies to enhance user experience and track website activity. A cookie is a small file stored on your device that helps us recognize you and provide personalized services.
                        </Typography>
                        <Typography variant="body2">
                            <strong>Types of Cookies:</strong>
                        </Typography>
                        <Typography variant="body2" component="ul">
                            <li><strong>Session Cookies:</strong> These are temporary cookies that expire once you close your browser.</li>
                            <li><strong>Persistent Cookies:</strong> These remain on your device for a set period or until you delete them.</li>
                        </Typography>
                        <Typography variant="body2">
                            You can manage your cookie preferences through your browser settings. However, disabling cookies may limit your use of certain features on our website.
                        </Typography>
                    </CardContent>
                </Card>

                {/* Section 5 - Data Security */}
                <Card sx={{ marginBottom: '1rem' }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            5. Data Security
                        </Typography>
                        <Typography variant="body2" paragraph>
                            We take the security of your personal information seriously. We employ technical and organizational measures to protect your data from unauthorized access, loss, misuse, or disclosure. These measures include encryption, firewalls, and secure server environments.
                        </Typography>
                        <Typography variant="body2">
                            However, no method of transmission over the internet or method of electronic storage is 100% secure. While we strive to protect your personal data, we cannot guarantee its absolute security.
                        </Typography>
                    </CardContent>
                </Card>

                {/* Section 6 - Data Retention */}
                <Card sx={{ marginBottom: '1rem' }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            6. Data Retention
                        </Typography>
                        <Typography variant="body2">
                            We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, or as required by law. If you close your account, we may retain some information for legal or legitimate business purposes, such as to comply with legal obligations, resolve disputes, or enforce agreements.
                        </Typography>
                    </CardContent>
                </Card>

                {/* Section 7 - Your Data Rights */}
                <Card sx={{ marginBottom: '1rem' }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            7. Your Data Rights
                        </Typography>
                        <Typography variant="body2" paragraph>
                            Depending on your location and applicable data protection laws, you may have the following rights regarding your personal information:
                        </Typography>
                        <Typography variant="body2" component="ul">
                            <li><strong>Access:</strong> You can request access to the personal information we hold about you.</li>
                            <li><strong>Correction:</strong> You have the right to request corrections to any inaccurate or incomplete data.</li>
                            <li><strong>Deletion:</strong> You can request that we delete your personal information, subject to certain exceptions (e.g., if the data is necessary for compliance with legal obligations).</li>
                            <li><strong>Objection:</strong> You have the right to object to the processing of your data for certain purposes.</li>
                        </Typography>
                        <Typography variant="body2">
                            To exercise these rights, please contact us at support@nuvio.in.
                        </Typography>
                    </CardContent>
                </Card>

                {/* Section 8 - Children's Privacy */}
                <Card sx={{ marginBottom: '1rem' }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            8. Children's Privacy
                        </Typography>
                        <Typography variant="body2">
                            ManageEcom.com is not intended for individuals under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that we have collected such information, we will take steps to delete it.
                        </Typography>
                    </CardContent>
                </Card>

                {/* Section 9 - International Transfers */}
                <Card sx={{ marginBottom: '1rem' }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            9. International Transfers
                        </Typography>
                        <Typography variant="body2">
                            If you are located outside of Maharashtra, India, please be aware that your information may be transferred to, stored, and processed in a country different from your own. By using our services, you consent to this transfer.
                        </Typography>
                    </CardContent>
                </Card>

                {/* Section 10 - Changes to This Privacy Policy */}
                <Card sx={{ marginBottom: '1rem' }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            10. Changes to This Privacy Policy
                        </Typography>
                        <Typography variant="body2">
                            We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the effective date will be updated accordingly. We encourage you to review this policy periodically to stay informed about how we are protecting your information.
                        </Typography>
                    </CardContent>
                </Card>

                {/* Section 11 - Contact Us */}
                <Card>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            11. Contact Us
                        </Typography>
                        <Typography variant="body2">
                            If you have any questions or concerns regarding this Privacy Policy, please contact us at:
                            <br />
                            Nuvio Technologies Private Limited 
                            <br />
                            Nuvio Technologies Pvt Ltd. Shop No. 2, Shakti Dham CHS, Plot No. 125, Sector - 21, Ghansoli, Navi Mumbai - 400701, Maharashtra, India
                            <br />
                            Email: support@nuvio.in
                            <br />
                            Phone: +91 7718895734
                        </Typography>
                    </CardContent>
                </Card>
            </Container>
            <Footer />
        </div>
    );
};

export default PrivacyPolicy;
